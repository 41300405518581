<form [formGroup]="receivedFormGroup">
    <div *ngIf="hasDayOfTheWeek" class="row g-0 d-flex justify-content-end mt-3">
        <div class="col me-2 d-flex align-items-center">
            <span class="montserrat-semibold fs-14 p-blue">{{ 'USAGE_PROFILE.EXCEPTION' | translate: {num: index + 1} }}</span>
        </div>
        <div class="col col-md-auto d-flex align-items-center align-self-end remove-icon" (click)="removeException(index)">
            <svg-icon src="assets/images/icons/trash.svg"></svg-icon>
        </div>
        <div class="row g-0 d-flex justify-content-end">
            <div class="col col-4 pe-2">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'USAGE_PROFILE.DAY_OF_THE_WEEK' | translate }}</mat-label>
                    <mat-select formControlName="dayOfTheWeek">
                        <mat-option *ngFor="let weekDay of weekDays" [value]="weekDay" class="montserrat-medium fs-14 s-black">
                            {{ getWeekDayTranslation(weekDay) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col col-4 ps-2">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'USAGE_PROFILE.NUMBER_OF_SHIFTS' | translate }}</mat-label>
                    <mat-select formControlName="numberOfShifts" [(ngModel)]="weekShiftDefault" [placeholder]="weekShiftDefault">
                        <mat-option *ngFor="let shift of shiftsDropdownValues" [value]="shift" class="montserrat-medium fs-14 s-black">
                            {{ shift | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div *ngIf="!hasDayOfTheWeek" class="row g-0">
        <div class="col col-4 pe-2 d-flex align-items-center">
            <span class="montserrat-semibold fs-14 p-blue">{{ title | translate }}</span>
        </div>
        <div class="col col-4 ps-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'USAGE_PROFILE.NUMBER_OF_SHIFTS' | translate }}</mat-label>
                <mat-select formControlName="numberOfShifts" [(ngModel)]="weekShiftDefault" [placeholder]="weekShiftDefault">
                    <mat-option *ngFor="let shift of shiftsDropdownValues" [value]="shift" class="montserrat-medium fs-14 s-black energy-droplist">
                        {{ shift | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row g-0">
        <ng-container formArrayName="shifts">
            <ng-container *ngFor="let shift of receivedFormGroup.get('shifts')['controls']; let shiftIndex = index">
                <div class="row g-0" [formGroup]="shift">
                    <div class="col me-2 d-flex align-items-center">
                        <span class="montserrat-regular fs-14 p-blue">{{ 'USAGE_PROFILE.SHIFT' | translate: {shift: shiftIndex + 1} }}</span>
                    </div>
                    <div class="col ms-2">
                        <app-timepicker type="start" [timeFormGroup]="shift.get('startTime')"></app-timepicker>
                    </div>
                    <div class="col ms-3">
                        <app-timepicker type="end" [timeFormGroup]="shift.get('endTime')"></app-timepicker>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</form>
