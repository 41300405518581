import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {EquipmentHighConsuption} from '@domain/equipment-high-consuption.enum';
import {EnumUtils} from '@shared/enum-utils';

@Component({
    selector: 'app-equipment-details',
    templateUrl: './equipment-details.component.html',
    styleUrls: ['./equipment-details.component.scss']
})
export class EquipmentDetailsComponent
{
    @Input() public translation: EquipmentHighConsuption;
    @Input() public receivedFormGroup: FormGroup;
    @Input() public isOtherEquipment: boolean;

    public getTranslationKey(equipment: EquipmentHighConsuption): string
    {
        return `ENERGY_EFICIENCY.EQUIPMENTS.${equipment}`;
    }

    public getEquipmentIcon(): string
    {
        let iconSrc: string;

        switch ((this.translation as unknown) as string)
        {
            case EnumUtils.getKeyFromValue(EquipmentHighConsuption, EquipmentHighConsuption.COMPRESSED_AIR):
                iconSrc = 'assets/images/icons/ar-comprimido.svg';
                break;
            case EnumUtils.getKeyFromValue(EquipmentHighConsuption, EquipmentHighConsuption.FURNACES):
                iconSrc = 'assets/images/icons/oven.svg';
                break;
            case EnumUtils.getKeyFromValue(EquipmentHighConsuption, EquipmentHighConsuption.CIRCULATOR_PUMPS):
                iconSrc = 'assets/images/icons/bomba-circuladoras.svg';
                break;
            case EnumUtils.getKeyFromValue(EquipmentHighConsuption, EquipmentHighConsuption.ELECTROCHEMICAL_PROCESSES):
                iconSrc = 'assets/images/icons/processos-eletroquimicos.svg';
                break;
            case EnumUtils.getKeyFromValue(EquipmentHighConsuption, EquipmentHighConsuption.VENTILATORS):
                iconSrc = 'assets/images/icons/ventilador.svg';
                break;
            default:
                iconSrc = 'assets/images/icons/add-pblue.svg';
        }

        return iconSrc;
    }
}
