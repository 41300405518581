<div class="row g-0 row-header">
    <div class="col-2 left-col">
        <div class="row g-0 d-flex align-items-center header border-bottom">
            <div class="col col-md-auto d-flex flex-column align-items-center col-header">
                <img src="assets/images/qualify_2.svg" alt="qualify teca logo" />
                <label class="logo-label montserrat-semibold fs-12 p-blue">{{ "ENERGY_CALULATOR" | translate }}</label>
            </div>
        </div>
    </div>
    <div class="col-1 header border-bottom col-interval"></div>
    <div class="col-6 col-title header border-bottom d-flex align-items-end">
        <span class=" montserrat-bold fs-20 p-blue text-uppercase">{{ 'RESULTS.SIMULATION_NAME' | translate: {companyName: getCompanyName()} }}</span>
    </div>
    <div class="col-3 right-col">
        <div class="row g-0 header border-bottom right-top-col">
            <app-switch-language></app-switch-language>
        </div>
    </div>
</div>
<div id=resultsDiv *ngIf="!loading" class="row g-0 row-simulator" [ngClass]="{'results-section': hasResults}">
    <div class="col-6 col-title">
        <div class="row g-0 mt-5">
            <span class="montserrat-bold p-blue fs-16">{{ 'RESULTS.YOUR_VS_RES_CONSUMPTION' | translate }}</span>
        </div>
        <div class="row g-0 d-flex align-items-center row-estimated-reduction position-relative" [ngClass]="{'submitted-pdf': adaptPageToPDF}">
            <div #levelArrow class="d-flex flex-column position-absolute level-pointer">
                <span class="montserrat-medium p-blue">{{'RESULTS.LEVEL' | translate}}</span>
                <img src="assets/images/blue_arrow.svg" alt="You Are Here Arrow">
            </div>
            <img src="assets/images/consumption_comparison.png" alt="VS Consumption">
            <div class="d-flex flex-column w-fit">
                <div class="d-flex flex-column comparison-values">
                    <div class="montserrat-bold p-blue summary-title">
                        {{'RESULTS.SUMMARY' | translate}}
                    </div>
                    <div class="montserrat-medium p-blue d-flex space-between">
                        <span>{{'RESULTS.COMPANY_CONSUMPTION_PERCENTAGE' | translate}} </span>
                        <span>{{isValuePositive()}}{{percentageOfCompany | number:'1.1-1'}}%</span>
                    </div>
                    <div class="montserrat-medium p-blue d-flex space-between">
                        <span class="graph-consumption">{{'RESULTS.AVERAGE_CONSUMPTION' | translate}}</span>
                        <span>{{averageConsumption | number:'1.1-1'}}</span>
                    </div>
                    <div class="montserrat-medium p-blue d-flex space-between">
                        <span>{{'RESULTS.YOUR_CONSUMPTION' | translate}}</span>
                        <span>{{totalCostPerYear | number:'1.1-1'}}</span>
                    </div>
                </div>
                <div class="d-flex flex-column comparison-values w-100">
                    <div class="montserrat-bold p-blue summary-title">
                        {{'RESULTS.LEGEND' | translate}}
                    </div>
                    <div class="montserrat-medium p-blue d-flex flex-column">
                        <span class="montserrat-medium p-blue d-flex align-items-center">
                            <div class="good-consumption"></div>
                            {{'RESULTS.LOW_CONSUMPTION' | translate}}
                        </span>
                        <span class="montserrat-medium p-blue d-flex align-items-center">
                            <div class="bad-consumption"></div>
                            {{'RESULTS.HIGH_CONSUMPTION' | translate}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-0">
            <span class="montserrat-bold p-blue fs-16">{{ 'RESULTS.CONSUMPTION_PER_UNIT_PRODUCED' | translate }}</span>
            <div class="row g-0 row-estimated-reduction">
                <div class="col col-12">
                    <div class="row g-0 d-flex justify-content-center">
                        <div class="col col-md-auto d-flex justify-content-center align-items-end">
                            <span class="montserrat-semibold p-blue consumption-value">{{ getConsumptionPerUnit() }}</span>
                            <span class="montserrat-semibold p-blue consumption-unit ms-3">{{ 'RESULTS.KWH_T' | translate }}</span>
                        </div>
                    </div>
                    <div class="row g-0 text-center">
                        <span class="montserrat-regular fs-12 legend">{{ 'RESULTS.ACTUAL_VALUE' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-0 row-energy-consumption">
            <span class="montserrat-bold p-blue fs-16">{{ 'RESULTS.ENERGY_CONSUMPTION' | translate }}</span>
            <div class="row g-0 mt-4">
                <div class="col me-2 p-border-top">
                    <svg-icon src="assets\images\icons\eletricidade.svg"></svg-icon>
                    <span class="montserrat-semibold p-blue fs-12 text-uppercase ms-2">{{ 'INFO_TO_ATTACH.ELETRICITY' | translate }}</span>
                    <div class="p-box d-flex flex-column justify-content-center align-items-center position-relative">
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium value-label">{{ getValueInKwh('electricity', false) }}</span>
                        </div>
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium kwh-label">{{ 'RESULTS.KWH' | translate }}</span>
                        </div>
                        <div class="pb-8">
                            <span class="montserrat-medium fs-12">({{ getActualEletricityConsumption() }} {{ 'RESULTS.TEP' | translate }})</span>
                        </div>
                    </div>
                </div>
                <div class="col me-2 ms-2 p-border-top">
                    <svg-icon src="assets/images/icons/gas-s-blue.svg"></svg-icon>
                    <span class="montserrat-semibold p-blue fs-12 text-uppercase ms-2">{{ 'INFO_TO_ATTACH.GAS' | translate }}</span>
                    <div class="p-box d-flex flex-column justify-content-center align-items-center position-relative">
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium value-label">{{ getValueInKwh('gas', false) }}</span>
                        </div>
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium kwh-label">{{ 'RESULTS.KWH' | translate }}</span>
                        </div>
                        <div class="pb-8">
                            <span class="montserrat-medium fs-12">({{ getActualGasConsumption() }} {{ 'RESULTS.TEP' | translate }})</span>
                        </div>
                    </div>
                </div>
                <div class="col ms-2 p-border-top">
                    <svg-icon src="assets/images/icons/add.svg"></svg-icon>
                    <span class="montserrat-semibold p-blue fs-12 text-uppercase ms-2">{{ 'INFO_TO_ATTACH.OTHER' | translate }}</span>
                    <div class="p-box d-flex flex-column justify-content-center align-items-center position-relative">
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium value-label">{{ getValueInKwh('other', false) }}</span>
                        </div>
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium kwh-label">{{ 'RESULTS.KWH' | translate }}</span>
                        </div>
                        <div class="pb-8">
                            <span class="montserrat-medium fs-12">({{ getActualOtherPowerSourceConsumption() }} {{ 'RESULTS.TEP' | translate }})</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row g-0 text-center mt-2">
                <span class="montserrat-regular fs-12 legend mb-3">{{ 'RESULTS.ACTUAL_VALUES' | translate }}</span>
            </div>
            <div class="row g-0 mt-4">
                <div class="col me-2 p-border-top s-color-border">
                    <svg-icon src="assets\images\icons\eletricidade.svg"></svg-icon>
                    <span class="montserrat-semibold s-blue fs-12 text-uppercase ms-2">{{ 'INFO_TO_ATTACH.ELETRICITY' | translate }}</span>
                    <div class="p-box s-box d-flex flex-column justify-content-center align-items-center position-relative">
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium value-label">{{ getValueInKwh('electricity', true) }}</span>
                        </div>
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium kwh-label">{{ 'RESULTS.KWH' | translate }}</span>
                        </div>
                        <div class="pb-8">
                            <span class="montserrat-medium fs-12">({{ getEstimatedEletricityConsumption() }} {{ 'RESULTS.TEP' | translate }})</span>
                        </div>
                    </div>
                </div>
                <div class="col me-2 ms-2 p-border-top s-color-border">
                    <svg-icon src="assets/images/icons/gas-s-blue.svg"></svg-icon>
                    <span class="montserrat-semibold s-blue fs-12 text-uppercase ms-2">{{ 'INFO_TO_ATTACH.GAS' | translate }}</span>
                    <div class="p-box s-box d-flex flex-column justify-content-center align-items-center position-relative">
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium value-label">{{ getValueInKwh('gas', true) }}</span>
                        </div>
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium kwh-label">{{ 'RESULTS.KWH' | translate }}</span>
                        </div>
                        <div class="pb-8">
                            <span class="montserrat-medium fs-12">({{ getEstimatedGasConsumption() }} {{ 'RESULTS.TEP' | translate }})</span>
                        </div>
                    </div>
                </div>
                <div class="col ms-2 p-border-top s-color-border">
                    <svg-icon src="assets/images/icons/add.svg"></svg-icon>
                    <span class="montserrat-semibold s-blue fs-12 text-uppercase ms-2">{{ 'INFO_TO_ATTACH.OTHER' | translate }}</span>
                    <div class="p-box s-box d-flex flex-column justify-content-center align-items-center position-relative">
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium value-label">{{ getValueInKwh('other', true) }}</span>
                        </div>
                        <div class="row g-0 d-flex align-items-center">
                            <span class="montserrat-medium kwh-label">{{ 'RESULTS.KWH' | translate }}</span>
                        </div>
                        <div class="pb-8">
                            <span class="montserrat-medium fs-12">({{ getEstimatedOtherPowerSourceConsumption() }} {{ 'RESULTS.TEP' | translate }})</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row g-0 text-center mt-2">
                <span class="montserrat-regular fs-12 legend mb-3">{{ 'RESULTS.ESTIMATED_VALUES' | translate }}</span>
            </div>
        </div>
        <div class="row g-0 row-energy-consumption breakPage">
            <span class="montserrat-bold p-blue fs-16">{{ 'RESULTS.ENERGY_COST' | translate }}</span>
            <div class="row g-0 mt-4">
                <div class="col me-2 p-border-top">
                    <svg-icon src="assets\images\icons\eletricidade.svg"></svg-icon>
                    <span class="montserrat-semibold p-blue fs-12 text-uppercase ms-2">{{ 'INFO_TO_ATTACH.ELETRICITY' | translate }}</span>
                    <div class="row g-0 d-flex align-items-center justify-content-center mt-2">
                        <div class="col col-md-auto">
                            <span class="montserrat-medium p-blue cost-units">{{ getEletricityCost() }},</span>
                        </div>
                        <div class="col col-md-auto col-decimals">
                            <span class="montserrat-medium p-blue cost-decimals">00€</span>
                        </div>
                    </div>
                </div>
                <div class="col ms-2 p-border-top">
                    <svg-icon src="assets/images/icons/gas-s-blue.svg"></svg-icon>
                    <span class="montserrat-semibold p-blue fs-12 text-uppercase ms-2">{{ 'INFO_TO_ATTACH.GAS' | translate }}</span>
                    <div class="row g-0 d-flex align-items-center justify-content-center mt-2">
                        <div class="col col-md-auto">
                            <span class="montserrat-medium p-blue cost-units">{{ getGasCost() }},</span>
                        </div>
                        <div class="col col-md-auto col-decimals">
                            <span class="montserrat-medium p-blue cost-decimals">00€</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row g-0 row-consumption-equipments">
            <span class="montserrat-bold p-blue fs-16">{{ 'RESULTS.ENERGY_CONSUMPTION_EQUIPMENTS' | translate }}</span>
            <div class="row g-0 mt-4">
                <div class="col me-3 d-flex flex-column align-items-center">
                    <div class="row g-0 overview-title">
                        <span class="montserrat-bold p-blue fs-14">{{ 'RESULTS.OVERVIEW' | translate }}</span>
                    </div>
                    <div class="row g-0">
                        <div *ngIf="pieChartData" class="chart">
                            <canvas baseChart [data]="pieChartData" [type]="pieChartType" [options]="pieChartOptions"></canvas>
                        </div>
                    </div>
                </div>
                <div class="col ms-3 d-flex flex-column align-items-center breakPage">
                    <div class="row g-0">
                        <span class="montserrat-bold p-blue fs-14 overview-title">{{ 'RESULTS.HIGHER_CONSUMPTION_EQUIPMENT' | translate }}</span>
                    </div>
                    <div class="row g-0">
                        <div *ngFor="let equipment of getTop3Consumption(); let index = index" class="row g-0 pt-2 equipment-row" [class.top1]="index === 0" [class.top2]="index === 1" [class.top3]="index === 2">
                            <div class="row g-0 d-flex justify-content-center">
                                <span class="montserrat-medium p-blue text-center mb-2" [class.top1-label]="index === 0" [class.top2-label]="index === 1" [class.top3-label]="index === 2">{{ 'ENERGY_EFICIENCY.EQUIPMENTS.' + equipment.name | translate }}</span>
                            </div>
                            <div class="row g-0 d-flex justify-content-center" *ngIf="isOtherEquipment(equipment)">
                                <span class="montserrat-medium p-blue text-center mb-2" [class.top1-label]="index === 0" [class.top2-label]="index === 1" [class.top3-label]="index === 2">({{equipment.equipmentName}})</span>
                            </div>
                            <div class="row g-0 d-flex justify-content-center">
                                <span class="montserrat-medium s-black text-center fs-12">{{ equipmentConsumptionPerYear(equipment) }} kWh</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="improvements" class="row g-0 row-improve-consumption breakPage">
            <span class="montserrat-bold fs-20 p-blue mb-5">{{ 'RESULTS.IMPROVE_CONSUMPTION_TITLE' | translate }}</span>
            <div class="row g-0">
                <div class="row g-0">
                    <div class="col col-md-auto me-2">
                        <svg-icon src="assets/images/icons/lamp.svg"></svg-icon>
                    </div>
                    <div class="col col-md-auto ms-2">
                        <span class="montserrat-bold fs-14 p-blue text-uppercase">{{ 'NAVIGATION.LIGHTING' | translate }}</span>
                    </div>
                </div>
                <div class="row g-0 mt-2">
                    <div *ngFor="let sentence of getLightningImprovementSentences()" class="row g-0">
                        <div class="col col-md-auto me-2">
                            <span class="montserrat-regular fs-12 s-black">•</span>
                        </div>
                        <div class="col">
                            <span class="montserrat-regular fs-12 s-black">{{ sentence | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isCompressedAirSelected()" class="row g-0 mt-5">
                <div class="row g-0">
                    <div class="col col-md-auto me-2">
                        <svg-icon src="assets/images/icons/compressed_air_results.svg"></svg-icon>
                    </div>
                    <div class="col col-md-auto ms-2">
                        <span class="montserrat-bold fs-14 p-blue text-uppercase">{{ 'RESULTS.OPTIMIZATION_COMPRESSED_AIR' | translate }}</span>
                    </div>
                </div>
                <div class="row g-0">
                    <span class="montserrat-regular fs-12 s-black my-3">{{ 'RESULTS.IMPROVE_CONSUMPTION.EXPECTED_SAVINGS' | translate: {min:25, max:30} }}</span>
                    <div *ngFor="let sentence of getCompressedAirImprovementSentences()" class="row g-0">
                        <div class="col col-md-auto me-2">
                            <span class="montserrat-regular fs-12 s-black">•</span>
                        </div>
                        <div class="col">
                            <span class="montserrat-regular fs-12 s-black">{{ sentence | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isFurnaceSelected()" class="row g-0 mt-5">
                <div class="row g-0">
                    <div class="col col-md-auto me-2">
                        <svg-icon src="assets/images/icons/calrofrio.svg"></svg-icon>
                    </div>
                    <div class="col col-md-auto ms-2">
                        <span class="montserrat-bold fs-14 p-blue text-uppercase">{{ 'RESULTS.OPTIMIZATION_COMBUSTION_SYSTEMS' | translate }}</span>
                    </div>
                </div>
                <div class="row g-0">
                    <span class="montserrat-regular fs-12 s-black my-3">{{ 'RESULTS.IMPROVE_CONSUMPTION.EXPECTED_SAVINGS' | translate: {min:25, max:30} }}</span>
                    <div *ngFor="let sentence of getCombustionSystemsImprovementSenteces()" class="row g-0">
                        <div class="col col-md-auto me-2">
                            <span class="montserrat-regular fs-12 s-black">•</span>
                        </div>
                        <div class="col">
                            <span class="montserrat-regular fs-12 s-black">{{ sentence | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isPumpsSelected()" class="row g-0 mt-5">
                <div class="row g-0">
                    <div class="col col-md-auto me-2">
                        <svg-icon src="assets/images/icons/bomba-circuladoras.svg"></svg-icon>
                    </div>
                    <div class="col col-md-auto ms-2">
                        <span class="montserrat-bold fs-14 p-blue text-uppercase">{{ 'RESULTS.ELECTRIC_MOTORS_PUMPS' | translate }}</span>
                    </div>
                </div>
                <div class="row g-0">
                    <span class="montserrat-regular fs-12 s-black my-3">{{ 'RESULTS.IMPROVE_CONSUMPTION.EXPECTED_SAVINGS' | translate: {min:15, max:20} }}</span>
                    <div *ngFor="let sentence of getEletricMotorsPumpsImprovementSenteces()" class="row g-0">
                        <div class="col col-md-auto me-2">
                            <span class="montserrat-regular fs-12 s-black">•</span>
                        </div>
                        <div class="col">
                            <span class="montserrat-regular fs-12 s-black">{{ sentence | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="canShowRenewableImprovements()" class="row g-0 mt-5">
                <div class="row g-0">
                    <div class="col col-md-auto me-2">
                        <svg-icon src="assets/images/icons/bomba-circuladoras.svg"></svg-icon>
                    </div>
                    <div class="col col-md-auto ms-2">
                        <span class="montserrat-bold fs-14 p-blue text-uppercase">{{ 'RESULTS.RENEWABLE_ENERGY_INTEGRATION' | translate }}</span>
                    </div>
                </div>
                <div class="row g-0">
                    <span class="montserrat-regular fs-12 s-black my-3">{{ 'RESULTS.IMPROVE_CONSUMPTION.RENEWABLE_ENERGY_INTEGRATION.OBSERVATION' | translate }}</span>
                    <div *ngIf="isSolarThermalSelected()" class="col me-2">
                        <span class="montserrat-medium fs-14 p-blue text-decoration-underline">{{ 'RESULTS.SOLAR_THERMAL' | translate }}</span>
                        <div *ngFor="let sentence of getSolarThermalImprovementSenteces()" class="row g-0">
                            <div class="col col-md-auto me-2">
                                <span class="montserrat-regular fs-12 s-black">•</span>
                            </div>
                            <div class="col">
                                <span class="montserrat-regular fs-12 s-black">{{ sentence | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isSolarFVSelected()" class="col ms-2">
                        <span class="montserrat-medium fs-14 p-blue text-decoration-underline">{{ 'RESULTS.SOLAR_FV' | translate }}</span>
                        <div *ngFor="let sentence of getSolarFVImprovementSentences()" class="row g-0">
                            <div class="col col-md-auto me-2">
                                <span class="montserrat-regular fs-12 s-black">•</span>
                            </div>
                            <div class="col">
                                <span class="montserrat-regular fs-12 s-black">{{ sentence | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="canFoodSectorImprovements()" class="row g-0 mt-5">
                <div class="row g-0">
                    <div class="col col-md-auto me-2">
                        <svg-icon src="assets/images/icons/bomba-circuladoras.svg"></svg-icon>
                    </div>
                    <div class="col col-md-auto ms-2">
                        <span class="montserrat-bold fs-14 p-blue text-uppercase">{{ 'RESULTS.MEASURES_FOOD_SECTOR' | translate }}</span>
                    </div>
                </div>
                <div class="row g-0">
                    <span class="montserrat-regular fs-12 s-black my-3">{{ 'RESULTS.IMPROVE_CONSUMPTION.EXPECTED_SAVINGS' | translate: {min:10, max:15} }}</span>
                    <div *ngFor="let sentence of getFoodSectorImprovementSentences()" class="row g-0">
                        <div class="col col-md-auto me-2">
                            <span class="montserrat-regular fs-12 s-black">•</span>
                        </div>
                        <div class="col">
                            <span class="montserrat-regular fs-12 s-black">{{ sentence | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id=sendButton class="row g-0 d-flex justify-content-end" [ngClass]="{'send-button-hide': hasResults, 'd-none': submitted}">
        <button class="btn primary-button montserrat-regular send-button mr-24" [ngClass]="{'d-none': submitted}" (click)="resetSubmission()">{{ 'RESULTS.BACK' | translate }}</button>
        <button class="btn primary-button montserrat-regular send-button" [ngClass]="{'d-none': submitted || alreadySubmittedAnEmail}" (click)="sendEmail()">{{ 'RESULTS.SEND_COPY' | translate }}</button>
    </div>
    <div class="row g-0 d-flex justify-content-end" *ngIf="fetchedEmailResults">
        <div class="montserrat-regular success-message" *ngIf="emailSent">
            <span>{{'RESULTS.SUCCESS' | translate}}</span>
        </div>
        <div class="montserrat-regular error-message" *ngIf="!emailSent">
            <span>{{'RESULTS.ERROR' | translate}}</span>
        </div>
    </div>
    <div class="row g-0 d-flex justify-content-end" *ngIf="showCantSendEmailMessage">
        <div class="montserrat-regular error-message">
            <span>{{'RESULTS.ALREADY_SENT' | translate}}</span>
        </div>
    </div>
</div>
