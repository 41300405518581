<div [formGroup]="form" class="row g-0 mb-2">
    <div class="col me-2">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'INFO_TO_ATTACH.ELETRICITY_PART.PRICE.' + type | translate }}</mat-label>
            <input class="ps-1" type="number" matInput formControlName="price" min="0">
            <img src="assets/images/icons/euro.svg" matSuffix alt="euro_icon" />
        </mat-form-field>
    </div>
    <div class="col ms-2">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'INFO_TO_ATTACH.ELETRICITY_PART.CONSUMPTION.' + type | translate }}</mat-label>
            <input class="ps-1" type="number" matInput formControlName="consumption" min="0">
        </mat-form-field>
    </div>
</div>
