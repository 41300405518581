import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule, BrowserTransferStateModule, TransferState} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InterceptedHttp} from '@core/intercepted';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {svgBrowserLoaderFactory} from '@shared/loaders/svg-browser-loader';
import {translateBrowserLoaderFactory} from '@shared/loaders/translate-browser.loader';
import {AngularSvgIconModule, SvgLoader} from 'angular-svg-icon';
import {NgChartsModule} from 'ng2-charts';
import {RoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './footer/footer.component';
import {HomepageComponent} from './homepage/homepage.component';
import {ResultComponent} from './result/result.component';
import {SharedModule} from './shared/shared.module';
import {SimulatorModule} from './simulator/simulator.module';

@NgModule({
    declarations:
        [
            AppComponent,
            HomepageComponent,
            ResultComponent,
            FooterComponent
        ],
    imports:
        [
            BrowserModule.withServerTransition({appId: 'serverApp'}),
            TransferHttpCacheModule,
            SharedModule,
            HttpClientModule,
            RoutingModule,
            TranslateModule.forRoot({
                loader:
                {
                    provide: TranslateLoader,
                    useFactory: translateBrowserLoaderFactory,
                    deps: [HttpClient, TransferState]
                }
            }),
            AngularSvgIconModule.forRoot({
                loader: {
                    provide: SvgLoader,
                    useFactory: svgBrowserLoaderFactory,
                    deps: [HttpClient, TransferState]
                }
            }),
            SimulatorModule,
            BrowserTransferStateModule,
            BrowserAnimationsModule,
            NgbModule,
            NgChartsModule
        ],
    providers:
        [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: InterceptedHttp,
                multi: true
            }
        ],
    bootstrap: [AppComponent]
})
export class AppModule
{ }
