import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomepageComponent} from './homepage/homepage.component';
import {ResultGuard} from './result/result-guard.guard';
import {ResultComponent} from './result/result.component';

const routes: Routes =
    [
        {
            path: ':lang',
            children:
                [
                    {
                        path: '',
                        redirectTo: 'homepage',
                        pathMatch: 'full'
                    },
                    {
                        path: 'homepage',
                        component: HomepageComponent
                    },
                    {
                        path: 'simulator',
                        loadChildren: (): Promise<any> => import('./simulator/simulator.module').then((m) => m.SimulatorModule)
                    },
                    {
                        path: 'result',
                        component: ResultComponent,
                        canActivate: [ResultGuard]
                    },
                    {
                        path: '**',
                        redirectTo: 'homepage'
                    }
                ]
        }
    ];

@NgModule({
    imports:
        [
            RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})
        ],
    exports:
        [
            RouterModule
        ]
})
export class RoutingModule
{ }
