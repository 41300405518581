import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-identification',
    templateUrl: './identification.component.html',
    styleUrls: ['./identification.component.scss']
})
export class IdentificationComponent
{
    @Output() public navigateForward = new EventEmitter<FormGroup>();
    private identificationFormIsSubmitted = false;
    public activities =
        [
            '10840_DESCRIPTION',
            '28930_DESCRIPTION',
            '25290_DESCRIPTION',
            '74900_DESCRIPTION'
        ];

    public identificationForm = new FormGroup({
        companyName: new FormControl('', [Validators.required]),
        companyPerson: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        contact: new FormControl(''),
        address: new FormControl(''),
        buildDate: new FormControl(''),
        companysActivity: new FormControl('', [Validators.required]),
        description: new FormControl('')
    });

    public isInvalid(inputName: string): boolean
    {
        return this.identificationForm.get(inputName) && this.identificationForm.get(inputName).invalid && this.identificationForm.get(inputName).touched;
    }

    public getValue(formControl: string): any
    {
        return this.identificationForm.get(formControl);
    }

    public onSubmit(): void
    {
        this.identificationFormIsSubmitted = true;
        if (this.identificationForm.valid)
        {
            window.scrollTo({top: 0});
            this.navigateForward.emit(this.identificationForm);
        }
    }

    public areRadiosInvalid(): boolean
    {
        return this.identificationFormIsSubmitted && this.identificationForm.get('companysActivity').invalid;
    }

    public getActivitiesTranslation(activity: string): string
    {
        return `IDENTIFICATION.${activity}`;
    }
}
