<form [formGroup]="naturalGasFormGroup" class="container" (ngSubmit)="onSubmit()">
    <div class="row g-0 mb-3">
        <div class="col">
            <svg-icon src="assets/images/icons/gas.svg"></svg-icon>
            <span class="montserrat-semibold fs-14 p-blue ms-2">{{ 'INFO_TO_ATTACH.NATURAL_GAS_PART.NATURAL_GAS_KWH' | translate }}</span>
        </div>
        <div class="col col-md-auto">
            <app-file-uploader type="natural_gas" [group]="naturalGasFormGroup"></app-file-uploader>
        </div>
    </div>
    <div class="row g-0 mb-3">
        <div class="col col-md-auto me-4 d-flex align-items-center">
            <span class="montserrat-semibold p-blue fs-16">PT</span>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'INFO_TO_ATTACH.NATURAL_GAS_PART.IUC' | translate }}</mat-label>
                <input class="ps-1" type="text" matInput formControlName="instalationUniversalCode">
            </mat-form-field>
        </div>
    </div>
    <div class="row g-0 mb-3">
        <label class="montserrat-bold fs-16 p-blue">{{ 'INFO_TO_ATTACH.NATURAL_GAS_PART.SUPPLY_PRESSURE_LEVEL' | translate }}</label>
        <mat-radio-group formControlName="supplyPressureLevel">
            <mat-radio-button class="m-1 d-flex align-items-start mt-2" *ngFor="let pressure of supplyPressureLevelOptions" [value]="pressure">
                <span class="montserrat-regular ts-14 s-black text-wrap">{{ getSupplyTransalation(pressure) | translate }}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="row g-0 mb-2">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'INFO_TO_ATTACH.NATURAL_GAS_PART.NATURAL_GAS_CONSUMPTION' | translate }}</mat-label>
            <input class="ps-1" type="number" matInput formControlName="naturalGasConsumption" min="0">
        </mat-form-field>
    </div>
    <div class="row g-0">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'INFO_TO_ATTACH.NATURAL_GAS_PART.NATURAL_GAS_COST' | translate }}</mat-label>
            <input class="ps-1" type="number" matInput formControlName="naturalGasCost" min="0">
            <img src="assets/images/icons/euro.svg" matSuffix alt="euro_icon" />
        </mat-form-field>
    </div>
</form>
