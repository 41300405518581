import {HttpClient} from '@angular/common/http';
import {makeStateKey, StateKey, TransferState} from '@angular/platform-browser';
import {SvgHttpLoader, SvgLoader} from 'angular-svg-icon';
import {Observable} from 'rxjs';
import {SvgServerLoader} from './svg-server.loader';

export class SvgBrowserLoader implements SvgLoader
{
    public constructor(
        private readonly http: HttpClient,
        private readonly transferState: TransferState
    )
    {}
    
    public getSvg(url: string): Observable<string>
    {
        const key: StateKey<number> = makeStateKey<number>(`transfer-svg:${url}`);
        const data = this.transferState.get(key, null);
        
        if (data)
        {
            return new Observable((observer) =>
            {
                observer.next(data as any);
                observer.complete();
            });
        }
        else
        {
            return new SvgHttpLoader(this.http).getSvg(url);
        }
    }
}

export function svgBrowserLoaderFactory(http: HttpClient, transferState: TransferState): SvgServerLoader | SvgBrowserLoader
{
    return new SvgBrowserLoader(http, transferState);
}
