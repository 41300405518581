<div class="row g-0 h-100">
    <div class="col-2 h-100 left-image-col">
        <div class="row g-0 d-flex align-items-center header border-bottom">
            <div class="col col-md-auto d-flex flex-column align-items-center col-header">
                <img src="assets/images/qualify_2.svg" alt="qualify teca logo" />
                <label class="logo-label montserrat-semibold fs-12 p-blue">{{ "ENERGY_CALULATOR" | translate }}</label>
            </div>
        </div>
        <div class="row g-0 row-image">
            <img class="side-image" [src]="getActiveStepImagePath()" [alt]="getSideImageAlt()">
        </div>
    </div>
    <div class="col-1 header border-bottom col-interval"></div>
    <div class="col-6 col-simulator">
        <div class="row g-0 d-flex align-items-center header row-top-nav">
            <div class="div-top-nav">
                <app-top-nav [navigationElements]="navigationElements"></app-top-nav>
            </div>
        </div>
        <div class="row g-0 simulator">
            <app-identification [hidden]="!isStepActive(1)" (navigateForward)="navigate($event, 1, 'f')"></app-identification>
            <app-info-to-attach [hidden]="!isStepActive(2)" (navigateBack)="navigate($event, 2, 'b')" (navigateForward)="navigate($event, 2, 'f')"></app-info-to-attach>
            <app-usage-profile [hidden]="!isStepActive(3)" (navigateBack)="navigate($event, 3, 'b')" (navigateForward)="navigate($event, 3, 'f')"></app-usage-profile>
            <app-energy-efficiency [hidden]="!isStepActive(4)" (navigateBack)="navigate($event, 4, 'b')" (navigateForward)="navigate($event, 4, 'f')"></app-energy-efficiency>
            <app-lighting [hidden]="!isStepActive(5)" (navigateBack)="navigate($event, 5, 'b')" (navigateForward)="navigate($event, 5, 'f')"></app-lighting>
            <app-renewables-and-energy-generation [hidden]="!isStepActive(6)" (navigateBack)="navigate($event, 6, 'b')" (navigateForward)="navigate($event, 6, 'f')"></app-renewables-and-energy-generation>
        </div>
    </div>
    <div class="col-3 right-col">
        <div class="row g-0 header border-bottom right-top-col">
            <app-switch-language></app-switch-language>
        </div>
    </div>
</div>
