import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-price-consumption',
    templateUrl: './price-consumption.component.html',
    styleUrls: ['./price-consumption.component.scss']
})
export class PriceConsumptionComponent
{
    @Input() public type: string;
    @Input() public form: FormGroup;
}
