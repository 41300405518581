<form [formGroup]="timeFormGroup">
    <div class="row g-0">
        <div class="col col-5">
            <mat-form-field appearance="standard">
                <mat-label>{{ label | translate }}</mat-label>
                <mat-select formControlName="hour" class="no-arrow">
                    <mat-option *ngFor="let hour of hours" [value]="hour" class="montserrat-medium fs-14 s-black text-right">{{ hour }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col col-2 d-flex align-items-center justify-content-center pb-2">:</div>
        <div class="col col-5">
            <mat-form-field appearance="standard">
                <mat-select formControlName="minute" class="no-arrow">
                    <mat-option *ngFor="let minute of minutes" [value]="minute" class="montserrat-medium fs-14 s-black">{{ minute }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>
