import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-custom-modal',
    templateUrl: './custom-modal.component.html',
    styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent
{
    public sendEmail = false;

    public constructor(private readonly activeModal: NgbActiveModal)
    { }

    public confirm(): void
    {
        localStorage.setItem('sendEmailCleanWatts', this.sendEmail ? 'send' : 'dontSend');
        this.activeModal.close(this.sendEmail);
    }

    public cancel(): void
    {
        this.activeModal.dismiss();
    }
}
