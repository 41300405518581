/* eslint-disable @typescript-eslint/no-unused-expressions */
import {Location, isPlatformBrowser} from '@angular/common';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {IEquipment} from '@domain/equipment';
import {EquipmentHighConsuption} from '@domain/equipment-high-consuption.enum';
import {LightType} from '@domain/light-type.enum';
import {OtherEnergySource} from '@domain/other-energy-source.enum';
import {Period} from '@domain/period.enum';
import {TranslateService} from '@ngx-translate/core';
import {FileUploaderService} from '@shared/file-uploader.service';
import {ChartConfiguration, ChartData, ChartType} from 'chart.js';
import {each, isEqual, orderBy, range} from 'lodash';
import {BaseChartDirective} from 'ng2-charts';
import {Subscription} from 'rxjs';
import AnualConsumption from '../../assets/anual-consumption.json';
import {Company} from './anual-consumption';
import {ConsumptionType} from './consumption-type';
// eslint-disable-next-line @typescript-eslint/no-require-imports
const html2pdf = typeof window !== 'undefined' ? require('html2pdf.js') : null;


@Component({
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit, AfterViewInit
{
    @ViewChild(BaseChartDirective) public chart: BaseChartDirective | undefined;
    @ViewChild('levelArrow') public levelSelector: ElementRef;

    public loading = true;
    public loadedChartData = false;
    public pieChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            }
        },
        animation: {
            onComplete: () =>
            {
                if (localStorage.getItem('sendEmailCleanWatts') === 'send' && !this.loadedChartData)
                {
                    this.submitted = true;
                    this.toPdf();
                    this.loadedChartData = true;
                }
            }
        }
    };

    public hasResults = false;

    public pieChartData: ChartData<'pie', number[], string | string[]>;
    public pieChartType: ChartType = 'pie';
    private readonly chartColors = ['#1B9DD8', '#EB7E23', '#61B346', '#C11E67', '#176596', '#F8BA14', '#0F6B37'];
    private expectedEnergySavingsDecimal: number;
    private formArray: any[] = [];
    private readonly eletricityCoefficient = 0.000215;
    private readonly naturalGasCoefficient = 1.077;
    private readonly lpgGasCoefficient = 1.099;
    private readonly peletesCoefficient = 0.401;
    private readonly biomassaCoefficient = 0.277;
    private readonly madeiraCoefficient = 0.33;
    private readonly dieselCoefficient = 1.01;
    private unitsProduced: number;
    private readonly workHoursInAYear = 2080;

    private pdf: any;

    private dischargeTotalConsumption = 0;
    private fluorescentTotalConsumption = 0;
    private incandescentTotalConsumption = 0;
    private ledTotalConsumption = 0;
    private dischargeReducedConsumption = 0;
    private fluorescentReducedConsumption = 0;
    private incandescentReducedConsumption = 0;
    private ledReducedConsumption = 0;

    private savingsLightsMercury = 0;
    private savingsLightsFluorescent = 0;
    private savingsLightsIncandescent = 0;
    private savingsGlobalLighting = 0;

    private compressedAirTotalConsumption = 0;
    private furnacesTotalConsumption = 0;
    private pumpsTotalConsumption = 0;
    private compressedAirReducedConsumption = 0;
    private furnacesReducedConsumption = 0;
    private pumpsReducedConsumption = 0;

    private savingsCompressedAir = 0;
    private savingsFurnaces = 0;
    private savingsPumps = 0;
    private savingsFoodSector = 0;

    public browserRefresh = false;
    public subscription: Subscription;

    public anualConsumptionList: Company[] = AnualConsumption;
    public filteredCompanies: Company[];
    public totalCostPerYear = 0;
    public levelPercentage = 100 / 7;
    public averageConsumption = 0;
    public companyConsumptionLevel = 1;
    public percentageOfCompany = 0;
    public topDistance: number[] = [-32, 0, 40, 74, 112, 144, 182];
    public submitted = false;
    public emailSent = false;
    public fetchedEmailResults = false;
    public firstEmail = true;
    public showCantSendEmailMessage = false;
    public isPlatformBrowser = false;
    public adaptPageToPDF = localStorage.getItem('sendEmailCleanWatts') === 'send';
    public alreadySubmittedAnEmail = localStorage.getItem('sendEmailCleanWatts') === 'send';

    public constructor(
        private readonly translate: TranslateService,
        private readonly location: Location,
        private readonly uploader: FileUploaderService,
        private readonly router: Router,
        private readonly renderer: Renderer2,
        private readonly sanitizer: DomSanitizer,
        private readonly changeDetectorRef: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private readonly platformId: any
    )
    {
        this.isPlatformBrowser = isPlatformBrowser(this.platformId);
    }

    public ngOnInit(): void
    {
        const formArrayFromState = (this.location.getState() as {formArray: any;}).formArray;
        if (!formArrayFromState && !localStorage.getItem('formArray'))
        {
            const urlLang = this.router.url.split('/')[1];
            this.router.navigate([`${urlLang}/homepage`]);
        }

        if (formArrayFromState)
        {
            this.formArray = formArrayFromState;
            localStorage.setItem('formArray', JSON.stringify(formArrayFromState));
        }
        else if (!formArrayFromState && localStorage.getItem('formArray'))
        {
            this.formArray = JSON.parse(localStorage.getItem('formArray'));
        }

        this.getLightingSavings();
        this.getEquipmentSavings();

        this.setEquipmentConsumption();

        this.expectedEnergySavingsDecimal = this.setExpectedEnergySavings() / 100;

        this.changeDetectorRef.detectChanges();

        this.unitsProduced = this.formArray[2].itemsProduced;

        this.loading = false;

        this.getCompanyTotalConsumption();

        this.pieChartData = {
            labels: this.getEquipmentsName(),
            datasets: [{
                data: this.getEquipmentsConsumption(),
                backgroundColor: this.chartColors
            }]
        };

        if (localStorage.getItem('sendEmailCleanWatts') === 'send')
        {
            this.submitted = true;
        }
    }

    public ngAfterViewInit(): void
    {
        this.renderer.setStyle(this.levelSelector.nativeElement, 'top', `${this.topDistance[this.companyConsumptionLevel - 1]}px`);
    }

    public getCompanyName(): string
    {

        return this.formArray[0].companyName;
    }

    public getCompanyPerson(): string
    {
        return this.formArray[0].companyPerson;
    }

    public roundToOneDecimal(value: number): number
    {
        return Math.round(value * 10) / 10;
    }

    public getConsumptionPerUnit(): number
    {
        return this.roundToOneDecimal((this.getValueInKwh('electricity', false) + this.getValueInKwh('gas', false) + this.getValueInKwh('other', false)) / this.unitsProduced);
    }

    public getEstimatedConsumptionPerUnit(): number
    {
        return this.roundToOneDecimal(this.formArray[1].eletricityFormGroup.consumption / this.unitsProduced * (1 - (isNaN(this.expectedEnergySavingsDecimal) ? 0 : this.expectedEnergySavingsDecimal)));
    }

    public getActualEletricityConsumption(): number
    {
        return this.roundToOneDecimal(this.formArray[1].eletricityFormGroup.consumption * this.eletricityCoefficient * 12);
    }

    public getEstimatedEletricityConsumption(): number
    {
        return this.roundToOneDecimal(this.getActualEletricityConsumption() * (1 - (isNaN(this.expectedEnergySavingsDecimal) ? 0 : this.expectedEnergySavingsDecimal)));
    }

    public getActualGasConsumption(): number
    {
        return this.roundToOneDecimal(this.formArray[1].naturalGasFormGroup.naturalGasConsumption * this.naturalGasCoefficient * 12 + this.formArray[1].lpgGasFormGroup.lgpGasConsumption * this.lpgGasCoefficient * 12);
    }

    public getEstimatedGasConsumption(): number
    {
        return this.roundToOneDecimal(this.getActualGasConsumption() * (1 - (isNaN(this.expectedEnergySavingsDecimal) ? 0 : this.expectedEnergySavingsDecimal)));
    }

    public getActualOtherPowerSourceConsumption(): number
    {
        const othersArray = this.formArray[1].otherFormGroup.otherArray;
        let consumption = 0;

        for (const other of othersArray)
        {
            let coefficient = 0;

            if (isEqual(other.sourceName, OtherEnergySource.PELLETS))
            {
                coefficient = this.peletesCoefficient;
            }
            else if (isEqual(other.sourceName, OtherEnergySource.BIOMASSA_PRIMARIA))
            {
                coefficient = this.biomassaCoefficient;
            }
            else if (isEqual(other.sourceName, OtherEnergySource.MADEIRA))
            {
                coefficient = this.madeiraCoefficient;
            }
            else if (isEqual(other.sourceName, OtherEnergySource.GASOLEO))
            {
                coefficient = this.dieselCoefficient;
            }

            consumption += other.otherConsumption * coefficient * 12;
        }

        return this.roundToOneDecimal(consumption);
    }

    public getEstimatedOtherPowerSourceConsumption(): number
    {
        return this.roundToOneDecimal(this.getActualOtherPowerSourceConsumption() * (1 - (isNaN(this.expectedEnergySavingsDecimal) ? 0 : this.expectedEnergySavingsDecimal)));
    }

    public getEletricityCost(): number
    {
        let cost: number;
        const eletricityFormGroup = this.formArray[1].eletricityFormGroup;

        if (isEqual(eletricityFormGroup.numberOfPeriods, Period.SINGLE))
        {
            cost = eletricityFormGroup.singlePeriodFormGroup.consumption * eletricityFormGroup.singlePeriodFormGroup.price;
        }
        else if (isEqual(eletricityFormGroup.numberOfPeriods, Period.BI_SCHEDULE))
        {
            cost = eletricityFormGroup.emptyOutPeriodFormGroup.consumption * eletricityFormGroup.emptyOutPeriodFormGroup.price +
                eletricityFormGroup.emptyPeriodFormGroup.consumption * eletricityFormGroup.emptyPeriodFormGroup.price;
        }
        else if (isEqual(eletricityFormGroup.numberOfPeriods, Period.TRI_SCHEDULE))
        {
            cost = eletricityFormGroup.emptyPeriodFormGroup.consumption * eletricityFormGroup.emptyPeriodFormGroup.price +
                eletricityFormGroup.pontaPeriodFormGroup.consumption * eletricityFormGroup.pontaPeriodFormGroup.price +
                eletricityFormGroup.fullPeriodFormGroup.consumption * eletricityFormGroup.fullPeriodFormGroup.price;
        }
        else if (isEqual(eletricityFormGroup.numberOfPeriods, Period.TETRA_SCHEDULE))
        {
            cost = eletricityFormGroup.fullPeriodFormGroup.consumption * eletricityFormGroup.fullPeriodFormGroup.price +
                eletricityFormGroup.pontaPeriodFormGroup.consumption * eletricityFormGroup.pontaPeriodFormGroup.price +
                eletricityFormGroup.superEmptyPeriodFormGroup.consumption * eletricityFormGroup.superEmptyPeriodFormGroup.price +
                eletricityFormGroup.emptyPeriodFormGroup.consumption * eletricityFormGroup.emptyPeriodFormGroup.price;
        }

        return Math.round(cost * 12);
    }

    public getGasCost(): number
    {
        return Math.round(this.formArray[1].naturalGasFormGroup.naturalGasCost * this.formArray[1].naturalGasFormGroup.naturalGasConsumption * 12 +
            this.formArray[1].lpgGasFormGroup.lgpGasCost * this.formArray[1].lpgGasFormGroup.lgpGasConsumption * 12);
    }

    public getUsedEquipments(): IEquipment[]
    {
        let equipments = [];

        if (this.formArray[3].equipmentCirculatorPumps)
        {
            equipments.push({name: EquipmentHighConsuption.CIRCULATOR_PUMPS, ...this.formArray[3].circulatorPumpsFormGroup});
        }
        if (this.formArray[3].equipmentCompressedAir)
        {
            equipments.push({name: EquipmentHighConsuption.COMPRESSED_AIR, ...this.formArray[3].compressedAirFormGroup});
        }
        if (this.formArray[3].equipmentElectrochemicalProcesses)
        {
            equipments.push({name: EquipmentHighConsuption.ELECTROCHEMICAL_PROCESSES, ...this.formArray[3].electrochemicalProcessesFormGroup});
        }
        if (this.formArray[3].equipmentFurnaces)
        {
            equipments.push({name: EquipmentHighConsuption.FURNACES, ...this.formArray[3].furnacesFormGroup});
        }
        if (this.formArray[3].equipmentOther)
        {
            equipments.push({name: EquipmentHighConsuption.OTHER_EQUIPMENT, ...this.formArray[3].otherFormGroup});
        }
        if (this.formArray[3].equipmentVentilators)
        {
            equipments.push({name: EquipmentHighConsuption.VENTILATORS, ...this.formArray[3].ventilatorFormGroup});
        }

        return equipments;
    }

    public getEquipmentsConsumption(): number[]
    {
        let consumptions: number[] = [];
        let equipmentsConsumption = 0;

        each(this.getUsedEquipments(), (equipment: IEquipment) =>
        {
            equipmentsConsumption += equipment.expectedPower * equipment.hoursPerDay * equipment.daysPerYear * equipment.numberOfDevices;

            return consumptions.push(equipment.expectedPower * equipment.hoursPerDay * equipment.daysPerYear * equipment.numberOfDevices);
        });

        consumptions.push(this.getValueInKwh('electricity', false) - equipmentsConsumption >= 0 ? this.getValueInKwh('electricity', false) - equipmentsConsumption : 0);

        return consumptions;
    }

    public getEquipmentsName(): string[]
    {
        let names: string[] = [];

        each(this.getUsedEquipments(), (equipment: IEquipment) => names.push(this.translate.instant(`ENERGY_EFICIENCY.EQUIPMENTS.${equipment.name}`)));
        names.push(this.translate.instant('ENERGY_EFICIENCY.EQUIPMENTS.REMAINING_CONSUMPTION'));

        return names;
    }

    public getTop3Consumption(): IEquipment[]
    {
        let top3: IEquipment[];

        top3 = orderBy(this.getUsedEquipments(), ['expectedPower'], ['desc']).splice(0, 3);

        return top3;
    }

    public getLightningImprovementSentences(): string[]
    {
        let improvements: string[] = [];
        const lightingGroup = this.formArray[4];

        if (lightingGroup.existingLightDischarge && isEqual(lightingGroup.dischargeFormGroup.lightType, LightType.MERCURY_VAPOR))
        {
            improvements.push('RESULTS.IMPROVE_CONSUMPTION.LIGHTING.SENTENCE_1');
        }
        if (lightingGroup.existingLightFluorescent)
        {
            improvements.push('RESULTS.IMPROVE_CONSUMPTION.LIGHTING.SENTENCE_2');
        }
        if (lightingGroup.existingLightIncandescent)
        {
            improvements.push('RESULTS.IMPROVE_CONSUMPTION.LIGHTING.SENTENCE_3');
        }
        if (!lightingGroup.lightCtrlMotionSensor)
        {
            improvements.push('RESULTS.IMPROVE_CONSUMPTION.LIGHTING.SENTENCE_4');
            improvements.push('RESULTS.IMPROVE_CONSUMPTION.LIGHTING.SENTENCE_5');
            improvements.push('RESULTS.IMPROVE_CONSUMPTION.LIGHTING.SENTENCE_6');
        }
        if (lightingGroup.lightCtrlMotionSensor && !lightingGroup.lightCtrlBrightness)
        {
            improvements.push('RESULTS.IMPROVE_CONSUMPTION.LIGHTING.SENTENCE_4');
        }
        if (lightingGroup.lightCtrlMotionSensor && !lightingGroup.lightCtrlCrepuscular)
        {
            improvements.push('RESULTS.IMPROVE_CONSUMPTION.LIGHTING.SENTENCE_5');
        }

        return improvements;
    }

    public isCompressedAirSelected(): boolean
    {
        return this.formArray[3].equipmentCompressedAir;
    }

    public getCompressedAirImprovementSentences(): string[]
    {
        return range(7).map((index: number) => `RESULTS.IMPROVE_CONSUMPTION.COMPRESSED_AIR.SENTENCE_${index + 1}`);
    }

    public isFurnaceSelected(): boolean
    {
        return this.formArray[3].equipmentFurnaces;
    }

    public getCombustionSystemsImprovementSenteces(): string[]
    {
        return range(6).map((index: number) => `RESULTS.IMPROVE_CONSUMPTION.COMBUSTION_SYSTEMS.SENTENCE_${index + 1}`);
    }

    public isPumpsSelected(): boolean
    {
        return this.formArray[3].equipmentFurnaces;
    }

    public getEletricMotorsPumpsImprovementSenteces(): string[]
    {
        return range(2).map((index: number) => `RESULTS.IMPROVE_CONSUMPTION.ELECTRIC_MOTORS_PUMPS.SENTENCE_${index + 1}`);
    }

    public isSolarThermalSelected(): boolean
    {
        return this.formArray[5].thermal;
    }

    public getSolarThermalImprovementSenteces(): string[]
    {
        return range(3).map((index: number) => `RESULTS.IMPROVE_CONSUMPTION.RENEWABLE_ENERGY_INTEGRATION.SOLAR_THERMAL.SENTENCE_${index + 1}`);
    }

    public isSolarFVSelected(): boolean
    {
        return this.formArray[5].photovoltaic;
    }

    public getSolarFVImprovementSentences(): string[]
    {
        return range(5).map((index: number) => `RESULTS.IMPROVE_CONSUMPTION.RENEWABLE_ENERGY_INTEGRATION.SOLAR_FV.SENTENCE_${index + 1}`);
    }

    public canShowRenewableImprovements(): boolean
    {
        return this.isSolarFVSelected() || this.isSolarFVSelected();
    }

    public canFoodSectorImprovements(): boolean
    {
        return isEqual(this.formArray[0].companysActivity, '10840 - Fabricação de condimentos e temperos');
    }

    public getFoodSectorImprovementSentences(): string[]
    {
        return range(4).map((index: number) => `RESULTS.IMPROVE_CONSUMPTION.MEASURES_FOOD_SECTOR.SENTENCE_${index + 1}`);
    }

    private getTotalConsumption(kwh: number): number
    {
        return this.workHoursInAYear * kwh;
    }

    private getLightingTotalConsumption(): number
    {
        const lightingGroup = this.formArray[4];

        this.dischargeTotalConsumption = lightingGroup.existingLightDischarge && this.getTotalConsumption(lightingGroup.dischargeFormGroup.lightPower) * lightingGroup.dischargeFormGroup.lightCount;
        this.fluorescentTotalConsumption = lightingGroup.existingLightFluorescent && this.getTotalConsumption(lightingGroup.fluorescentFormGroup.lightPower) * lightingGroup.fluorescentFormGroup.lightCount;
        this.incandescentTotalConsumption = lightingGroup.existingLightIncandescent && this.getTotalConsumption(lightingGroup.incandescentVaporFormGroup.lightPower) * lightingGroup.incandescentVaporFormGroup.lightCount;
        this.ledTotalConsumption = lightingGroup.existingLightLed && this.getTotalConsumption(lightingGroup.ledFormGroup.lightPower) * lightingGroup.ledFormGroup.lightCount;

        return this.dischargeTotalConsumption + this.fluorescentTotalConsumption + this.incandescentTotalConsumption + this.ledTotalConsumption;
    }

    private getLightingSavings(): void
    {
        const lightingGroup = this.formArray[4];

        if (lightingGroup.existingLightDischarge && isEqual(lightingGroup.dischargeFormGroup.lightType, LightType.MERCURY_VAPOR))
        {
            this.savingsLightsMercury = 0.5;
        }
        if (lightingGroup.existingLightFluorescent)
        {
            this.savingsLightsFluorescent = 0.25;
        }
        if (lightingGroup.existingLightIncandescent)
        {
            this.savingsLightsIncandescent = 0.7;
        }
        if (!lightingGroup.lightCtrlMotionSensor || !lightingGroup.lightCtrlBrightness)
        {
            this.savingsGlobalLighting += 0.1;
        }
        if (!lightingGroup.lightCtrlMotionSensor || !lightingGroup.lightCtrlCrepuscular)
        {
            this.savingsGlobalLighting += 0.1;
        }
        if (!lightingGroup.lightCtrlMotionSensor)
        {
            this.savingsGlobalLighting += 0.1;
        }
    }

    private getLightingReducedConsumption(): number
    {
        this.dischargeReducedConsumption = this.dischargeTotalConsumption - this.dischargeTotalConsumption *
            ((this.formArray[4].existingLightDischarge && isEqual(this.formArray[4].dischargeFormGroup.lightType, LightType.MERCURY_VAPOR)
                ? this.savingsLightsMercury
                : 0) + this.savingsGlobalLighting);

        this.fluorescentReducedConsumption = this.fluorescentTotalConsumption - this.fluorescentTotalConsumption * (this.savingsLightsFluorescent + this.savingsGlobalLighting);
        this.incandescentReducedConsumption = this.incandescentTotalConsumption - this.incandescentTotalConsumption * (this.savingsLightsIncandescent + this.savingsGlobalLighting);
        this.ledReducedConsumption = this.ledTotalConsumption - this.ledTotalConsumption * this.savingsGlobalLighting;

        return this.roundToOneDecimal(this.dischargeReducedConsumption + this.fluorescentReducedConsumption + this.incandescentReducedConsumption + this.ledReducedConsumption);
    }

    private getEquipmentSavings(): void
    {
        if (this.isCompressedAirSelected())
        {
            this.savingsCompressedAir = 0.25;
        }
        if (this.isFurnaceSelected())
        {
            this.savingsFurnaces = 0.25;
        }
        if (this.isPumpsSelected())
        {
            this.savingsPumps = 0.15;
        }
        if (this.canFoodSectorImprovements())
        {
            this.savingsFoodSector = 0.10;
        }
    }

    private getEquipmentTotalConsumption(): number
    {
        return this.compressedAirTotalConsumption + this.furnacesTotalConsumption + this.pumpsTotalConsumption;
    }

    private getEquipmentReducedConsumption(): number
    {
        this.compressedAirReducedConsumption = this.compressedAirTotalConsumption - this.compressedAirTotalConsumption * this.savingsCompressedAir;
        this.furnacesReducedConsumption = this.furnacesTotalConsumption - this.furnacesTotalConsumption * this.savingsFurnaces;
        this.pumpsReducedConsumption = this.pumpsTotalConsumption - this.pumpsTotalConsumption * this.savingsPumps;

        return this.roundToOneDecimal(this.compressedAirReducedConsumption + this.furnacesReducedConsumption + this.pumpsReducedConsumption);
    }

    private setExpectedEnergySavings(): number
    {
        return Math.round(100 - (this.getLightingReducedConsumption() + this.getEquipmentReducedConsumption()) * 100 / (this.getLightingTotalConsumption() + this.getEquipmentTotalConsumption()) + this.savingsFoodSector * 100);
    }

    private getUserEmail(): string
    {
        return this.formArray[0].email;
    }

    private loadLogo(imgUrl): Promise<string>
    {
        return new Promise((resolve) =>
        {
            const image = new Image();

            image.onload = (): void =>
            {
                const canvas: HTMLCanvasElement = document.createElement('CANVAS') as HTMLCanvasElement;
                const ctx = canvas.getContext('2d');
                canvas.height = image.height;
                canvas.width = image.width;

                ctx.drawImage(image, 0, 0);
                resolve(canvas.toDataURL('image/png'));
            };

            image.src = imgUrl;
        });
    }

    private toPdf(): void
    {
        this.adaptPageToPDF = true;
        const pdfContainer: HTMLElement = document.getElementById('resultsDiv');

        if (this.isPlatformBrowser)
        {
            var opt = {
                margin: [1.5, -1, 1, -1],
                filename: `${this.getCompanyName()} - Resultado da simulacao - Qualify.teca.pdf`,
                pagebreak: {mode: 'css', before: '.breakPage'},
                image: {type: 'jpeg', quality: 0.75},
                html2canvas: {scale: 1},
                jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}

            };

            Promise.all([
                this.loadLogo('/assets/images/homepage/qualify-teca-homepage.svg'),
                this.loadLogo('/assets/images/pdf_footer_template.png')])
                .then(([headerLogoBase64, footerLogoBase64]: [string, string]) =>
                {
                    html2pdf().from(pdfContainer).set(opt).toCanvas().toPdf().get('pdf').then((pdf) =>
                    {
                        const totalPages = pdf.internal.getNumberOfPages();

                        for (var i = 1; i <= totalPages; i++)
                        {
                            pdf.setPage(i);
                            pdf.addImage(headerLogoBase64, 'PNG', 0.5, 0.5, 0.8, 0.8);
                            pdf.addImage(footerLogoBase64, 'PNG', 0.5, 10, pdf.internal.pageSize.getWidth() - 1, 0.8);
                        }
                        this.pdf = pdf.output('blob');
                        this.uploader.addFile(`${this.getCompanyName()} - Resultado da simulacao - Qualify.teca.pdf`, new File([this.pdf], `${this.getCompanyName()} - Resultado da simulacao - Qualify.teca.pdf`, {type: 'application/pdf'}));
                        this.uploader.sendEmail(this.getUserEmail(), this.getCompanyPerson()).subscribe({
                            next: () =>
                            {
                                localStorage.removeItem('formArray');
                                this.updateMessageStatus(true);
                                this.uploader.clearFormData();
                            },
                            error: () => this.updateMessageStatus(false)
                        });
                    });
                });
        }
    }

    public sendEmail(): void
    {

        if (this.firstEmail)
        {
            this.submitted = true;
            this.toPdf();
            this.firstEmail = false;
        }
        else
        {
            this.fetchedEmailResults = false;
            this.showCantSendEmailMessage = true;

            setTimeout(() =>
            {
                this.showCantSendEmailMessage = false;
            }, 5000);
        }

    }

    public getValueInKwh(type: string, estimated: boolean): number
    {
        let electricityConsumption = this.formArray[1].eletricityFormGroup.consumption;
        let gasConsumption = +this.formArray[1].lpgGasFormGroup.lgpGasConsumption + +this.formArray[1].naturalGasFormGroup.naturalGasConsumption;

        switch (type)
        {
            case ConsumptionType.ELECTRICITY: {
                return !estimated ? this.roundToOneDecimal(electricityConsumption * 12) : this.roundToOneDecimal(electricityConsumption * (1 - (isNaN(this.expectedEnergySavingsDecimal) ? 0 : this.expectedEnergySavingsDecimal)) * 12);
            }
            case ConsumptionType.GAS: {
                return !estimated ? this.roundToOneDecimal(gasConsumption * 12) : this.roundToOneDecimal(gasConsumption * (1 - (isNaN(this.expectedEnergySavingsDecimal) ? 0 : this.expectedEnergySavingsDecimal)) * 12);
            }
            case ConsumptionType.OTHER: {
                const othersArray = this.formArray[1].otherFormGroup.otherArray;
                let consumption = 0;

                for (const other of othersArray)
                {
                    consumption += other.otherConsumption;
                }

                return !estimated ? this.roundToOneDecimal(consumption * 12) : this.roundToOneDecimal(consumption * (1 - (isNaN(this.expectedEnergySavingsDecimal) ? 0 : this.expectedEnergySavingsDecimal)) * 12);
            }
        }
    }


    public getCompanyTotalConsumption(): void
    {
        let maxConsumptionToCompare = 0;

        this.filteredCompanies = this.anualConsumptionList.filter((company) => +company.cae === +this.formArray[0].companysActivity.split('_')[0]);

        this.totalCostPerYear = this.getValueInKwh('electricity', false);

        this.filteredCompanies.forEach((company: Company) =>
        {
            this.averageConsumption += company.anualConsumption;
            maxConsumptionToCompare = company.anualConsumption > maxConsumptionToCompare ? company.anualConsumption : maxConsumptionToCompare;
        });

        this.averageConsumption = this.averageConsumption / this.filteredCompanies.length;
        const companyValue = (this.totalCostPerYear - this.averageConsumption) / this.averageConsumption * 100;

        this.percentageOfCompany = companyValue;

        this.topDistance.forEach(() =>
        {
            if (this.percentageOfCompany > this.companyConsumptionLevel * this.levelPercentage)
            {
                this.companyConsumptionLevel += this.companyConsumptionLevel < 7 ? 1 : 0;
            }
        });
    }

    public isOtherEquipment(equipment: IEquipment): boolean
    {
        return equipment.name === 'OTHER_EQUIPMENT';
    }

    public resetSubmission(): void
    {
        localStorage.removeItem('formArray');
        const lang = this.translate.currentLang;
        this.router.navigateByUrl(`${lang}/homepage`);
    }

    public updateMessageStatus(emailWasSent: boolean): void
    {

        this.adaptPageToPDF = false;
        this.emailSent = emailWasSent;
        this.fetchedEmailResults = true;
        this.submitted = false;
        this.changeDetectorRef.detectChanges();
        setTimeout(() =>
        {
            this.emailSent = false;
            this.fetchedEmailResults = false;
            this.changeDetectorRef.detectChanges();
        }, 5000);
    }

    public equipmentConsumptionPerYear(equipment: any): number
    {
        return equipment.expectedPower * equipment.hoursPerDay * equipment.daysPerYear * equipment.numberOfDevices;
    }

    public setEquipmentConsumption(): void
    {
        this.compressedAirTotalConsumption = this.formArray[3].equipmentCompressedAir && this.getTotalConsumption(this.formArray[3].compressedAirFormGroup.expectedPower) * this.formArray[3].compressedAirFormGroup.numberOfDevices;
        this.furnacesTotalConsumption = this.formArray[3].equipmentFurnaces && this.getTotalConsumption(this.formArray[3].furnacesFormGroup.expectedPower) * this.formArray[3].furnacesFormGroup.numberOfDevices;
        this.pumpsTotalConsumption = this.formArray[3].equipmentCirculatorPumps && this.getTotalConsumption(this.formArray[3].circulatorPumpsFormGroup.expectedPower) * this.formArray[3].circulatorPumpsFormGroup.numberOfDevices;
    }

    public isValuePositive(): string
    {
        return this.percentageOfCompany > 0 ? '+' : '';
    }
}

