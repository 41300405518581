<div class="row g-0 title d-flex align-items-end pb-1">
    <span class="montserrat-bold fs-20 p-blue text-uppercase">{{ getActiveNavigationElement().translationKey | translate }}</span>
</div>
<div class="row g-0">
    <div *ngFor="let navigationElement of navigationElements" class="col col-2">
        <div class="row g-0 bar" [class.active-bar]="navigationElement.active"></div>
        <div class="row g-0 mx-5 col-navigation">
            <span class="text-center montserrat-regular fs-16 mt-1 inactive-step" [class.active-step]="navigationElement.active">{{ navigationElement.step }}</span>
        </div>
    </div>
</div>
