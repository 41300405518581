/* eslint-disable @typescript-eslint/no-unused-expressions */
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IEnergyEficiencyEquipment} from '@domain/energy-eficiency-equipment';
import {EquipmentHighConsuption} from '@domain/equipment-high-consuption.enum';
import {EnumUtils} from '@shared/enum-utils';
import {each, noop} from 'lodash';

@Component({
    selector: 'app-energy-efficiency',
    templateUrl: './energy-efficiency.component.html',
    styleUrls: ['./energy-efficiency.component.scss']
})
export class EnergyEfficiencyComponent implements OnInit
{
    @Output() public navigateBack = new EventEmitter<FormGroup>();
    @Output() public navigateForward = new EventEmitter<FormGroup>();

    public nextButtonPressed = false;

    public equipments: IEnergyEficiencyEquipment[] =
        [
            {
                translation: EnumUtils.getKeyFromValue(EquipmentHighConsuption, EquipmentHighConsuption.COMPRESSED_AIR),
                formGroupName: 'compressedAirFormGroup',
                formName: 'equipmentCompressedAir',
                checked: false
            },
            {
                translation: EnumUtils.getKeyFromValue(EquipmentHighConsuption, EquipmentHighConsuption.FURNACES),
                formGroupName: 'furnacesFormGroup',
                formName: 'equipmentFurnaces',
                checked: false
            },
            {
                translation: EnumUtils.getKeyFromValue(EquipmentHighConsuption, EquipmentHighConsuption.CIRCULATOR_PUMPS),
                formGroupName: 'circulatorPumpsFormGroup',
                formName: 'equipmentCirculatorPumps',
                checked: false
            },
            {
                translation: EnumUtils.getKeyFromValue(EquipmentHighConsuption, EquipmentHighConsuption.ELECTROCHEMICAL_PROCESSES),
                formGroupName: 'electrochemicalProcessesFormGroup',
                formName: 'equipmentElectrochemicalProcesses',
                checked: false
            },
            {
                translation: EnumUtils.getKeyFromValue(EquipmentHighConsuption, EquipmentHighConsuption.VENTILATORS),
                formGroupName: 'ventilatorFormGroup',
                formName: 'equipmentVentilators',
                checked: false
            },
            {
                translation: EnumUtils.getKeyFromValue(EquipmentHighConsuption, EquipmentHighConsuption.OTHER_EQUIPMENT),
                formGroupName: 'otherFormGroup',
                formName: 'equipmentOther',
                checked: false
            }
        ];

    public eficiencyFormGroup = new FormGroup({
        radioEficiencyPolicy: new FormControl('', Validators.required),
        eficiencyPolicyDescription: new FormControl('', Validators.required),
        radioImplementedMeasures: new FormControl('', Validators.required),
        replacementToLed: new FormControl(''),
        installationCapacitorBanks: new FormControl(''),
        electronicSpeedVariators: new FormControl(''),
        highEfficiencyMotors: new FormControl(''),
        compressedAirOptimization: new FormControl(''),
        thermalSystemsOptimization: new FormControl(''),
        radioMonitoring: new FormControl('', Validators.required),
        radioSomeoneValidatingInvoices: new FormControl(''),
        equipmentCompressedAir: new FormControl(false),
        equipmentFurnaces: new FormControl(false),
        equipmentCirculatorPumps: new FormControl(false),
        equipmentElectrochemicalProcesses: new FormControl(false),
        equipmentVentilators: new FormControl(false),
        equipmentOther: new FormControl(false)
    });

    public constructor(private readonly formBuilder: FormBuilder)
    { }

    public ngOnInit(): void
    {
        this.eficiencyFormGroup.get('radioEficiencyPolicy').valueChanges.subscribe((value) =>
        {
            if (value)
            {
                this.eficiencyFormGroup.get('eficiencyPolicyDescription').setValidators(Validators.required);
            }
            else
            {
                this.eficiencyFormGroup.get('eficiencyPolicyDescription').clearValidators();
                this.eficiencyFormGroup.get('eficiencyPolicyDescription').updateValueAndValidity();
            }
        });

        this.eficiencyFormGroup.get('equipmentCompressedAir').valueChanges.subscribe((value) =>
        {
            value ? this.eficiencyFormGroup.addControl('compressedAirFormGroup', this.getBaseFormGroup()) : this.eficiencyFormGroup.removeControl('compressedAirFormGroup');
        });

        this.eficiencyFormGroup.get('equipmentFurnaces').valueChanges.subscribe((value) =>
        {
            value ? this.eficiencyFormGroup.addControl('furnacesFormGroup', this.getBaseFormGroup()) : this.eficiencyFormGroup.removeControl('furnacesFormGroup');
        });

        this.eficiencyFormGroup.get('equipmentCirculatorPumps').valueChanges.subscribe((value) =>
        {
            value ? this.eficiencyFormGroup.addControl('circulatorPumpsFormGroup', this.getBaseFormGroup()) : this.eficiencyFormGroup.removeControl('circulatorPumpsFormGroup');
        });

        this.eficiencyFormGroup.get('equipmentElectrochemicalProcesses').valueChanges.subscribe((value) =>
        {
            value ? this.eficiencyFormGroup.addControl('electrochemicalProcessesFormGroup', this.getBaseFormGroup()) : this.eficiencyFormGroup.removeControl('electrochemicalProcessesFormGroup');
        });

        this.eficiencyFormGroup.get('equipmentVentilators').valueChanges.subscribe((value) =>
        {
            value ? this.eficiencyFormGroup.addControl('ventilatorFormGroup', this.getBaseFormGroup()) : this.eficiencyFormGroup.removeControl('ventilatorFormGroup');
        });

        this.eficiencyFormGroup.get('equipmentOther').valueChanges.subscribe((value) =>
        {
            if (value)
            {
                this.eficiencyFormGroup.addControl('otherFormGroup', this.getBaseFormGroup());
                (this.eficiencyFormGroup.get('otherFormGroup') as FormGroup).addControl('equipmentName', new FormControl('', Validators.required));
            }
            else
            {
                this.eficiencyFormGroup.removeControl('otherFormGroup');
            }
        });
    }

    public areRadiosInvalid(controlName: string): boolean
    {
        return this.nextButtonPressed && this.eficiencyFormGroup.get(controlName).invalid;
    }

    public canShowAddicionalField(controlName: string): boolean
    {
        return this.eficiencyFormGroup.get(controlName).value;
    }

    public areEficiencyMeasuresCheckboxesInvalid(): boolean
    {
        return this.eficiencyFormGroup.get('radioImplementedMeasures').value && this.nextButtonPressed
            && !this.eficiencyFormGroup.get('replacementToLed').value
            && !this.eficiencyFormGroup.get('installationCapacitorBanks').value
            && !this.eficiencyFormGroup.get('electronicSpeedVariators').value
            && !this.eficiencyFormGroup.get('highEfficiencyMotors').value
            && !this.eficiencyFormGroup.get('compressedAirOptimization').value
            && !this.eficiencyFormGroup.get('thermalSystemsOptimization').value;
    }

    public equipmentClick(equipment: IEnergyEficiencyEquipment): void
    {
        equipment.checked = !equipment.checked;
    }

    public getEquipmentsClicked(): IEnergyEficiencyEquipment[]
    {
        let equipmentsSelected = [];

        each(this.equipments, (equipment: IEnergyEficiencyEquipment) => equipment.checked ? equipmentsSelected.push(equipment) : noop);

        return equipmentsSelected;
    }

    public areEquipmentCheckboxesInvalid(): boolean
    {
        return this.nextButtonPressed
            && !this.eficiencyFormGroup.get('equipmentCompressedAir').value
            && !this.eficiencyFormGroup.get('equipmentFurnaces').value
            && !this.eficiencyFormGroup.get('equipmentCirculatorPumps').value
            && !this.eficiencyFormGroup.get('equipmentElectrochemicalProcesses').value
            && !this.eficiencyFormGroup.get('equipmentVentilators').value
            && !this.eficiencyFormGroup.get('equipmentOther').value;
    }

    public getTranslationKey(equipment: EquipmentHighConsuption): string
    {
        return `ENERGY_EFICIENCY.EQUIPMENTS.${equipment}`;
    }

    private getBaseFormGroup(): FormGroup
    {
        return this.formBuilder.group({
            expectedPower: new FormControl('', Validators.required),
            hoursPerDay: new FormControl('', [Validators.required, Validators.max(24)]),
            daysPerYear: new FormControl('', [Validators.required, Validators.max(366)]),
            numberOfDevices: new FormControl('', Validators.required)
        });
    }

    public navigateToPreviousPage(): void
    {
        window.scrollTo({top: 0});
        this.navigateBack.emit(this.eficiencyFormGroup);
    }

    public onSubmit(): void
    {
        this.nextButtonPressed = true;
        if (this.eficiencyFormGroup.valid && !this.areEficiencyMeasuresCheckboxesInvalid() && !this.areEquipmentCheckboxesInvalid())
        {
            window.scrollTo({top: 0});
            this.navigateForward.emit(this.eficiencyFormGroup);
        }
    }

    public isOtherEquipmentForm(formGroupName: string): boolean
    {
        return formGroupName === 'otherFormGroup';
    }
}
