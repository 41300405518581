export enum EnergyRating
{
    A_3PLUS = 'A+++',
    A_2PLUS = 'A++',
    A_PLUS = 'A+',
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G'
}
