<form [formGroup]="otherFormGroup" class="container">
    <div class="row g-0 mb-3">
        <div class="col">
            <svg-icon src="assets/images/icons/add.svg"></svg-icon>
            <span class="montserrat-semibold fs-14 p-blue ms-2">{{ 'INFO_TO_ATTACH.OTHER' | translate }}</span>
        </div>
        <div class="col col-md-auto">
            <app-file-uploader type="other" [group]="otherFormGroup"></app-file-uploader>
        </div>
    </div>
    <ng-container *ngFor="let other of others.controls; let i = index">
        <div class="row g-0 mb-2" [formGroup]="other">
            <div class="col me-2">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'INFO_TO_ATTACH.OTHER_PART.SOURCE_NAME' | translate }}</mat-label>
                    <mat-select formControlName="sourceName">
                        <mat-option *ngFor="let source of sourceNames" [value]="source" class="montserrat-medium fs-14 s-black">
                            {{ getSourceNameTranslation(source) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col ms-2 me-2">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'INFO_TO_ATTACH.ELETRICITY_PART.CONSUMPTION_EX' | translate }}</mat-label>
                    <input class="ps-1" type="number" matInput formControlName="otherConsumption" min="0">
                </mat-form-field>
            </div>
            <div class="col ms-2 me-2">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'INFO_TO_ATTACH.OTHER_PART.PRICE' | translate }}</mat-label>
                    <input class="ps-1" type="number" matInput formControlName="otherPrice" min="0">
                    <img src="assets/images/icons/euro.svg" matSuffix alt="euro_icon" />
                </mat-form-field>
            </div>
            <div class="col col-md-auto ms-2 d-flex align-items-center remove-icon" [class.disabled]="!canRemoveOther()" (click)="removeOther(i)">
                <svg-icon src="assets/images/icons/trash.svg"></svg-icon>
            </div>
        </div>
    </ng-container>
    <div *ngIf="!othersHasMaxElements()" class="row g-0 d-flex justify-content-end">
        <div class="col col-md-auto">
            <span class="montserrat-medium fs-14 s-blue text-decoration-underline me-1 text-link" (click)="addNewOther()">{{ 'ADD' | translate }}</span>
        </div>
    </div>
</form>
