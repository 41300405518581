/* eslint-disable @typescript-eslint/no-unused-expressions */
import {isPlatformBrowser} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Analytics, getAnalytics, logEvent, setAnalyticsCollectionEnabled} from 'firebase/analytics';
import {initializeApp} from 'firebase/app';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService
{
    private readonly analytics: Analytics;
    private readonly isPlatformBrowser: boolean;

    private readonly firebaseConfig = {
        apiKey: 'AIzaSyA5a99tG1lQKSf6zaJmLWKSY_vFuozoC4M',
        authDomain: 'cleanwatts.firebaseapp.com',
        projectId: 'cleanwatts',
        storageBucket: 'cleanwatts.appspot.com',
        messagingSenderId: '216712667991',
        appId: '1:216712667991:web:b9d7f9a634b37057691412',
        measurementId: 'G-6T6D8359GP'
    };

    public constructor(@Inject(PLATFORM_ID) private readonly platformId: any)
    {
        this.isPlatformBrowser = isPlatformBrowser(this.platformId);

        if (this.isPlatformBrowser)
        {
            const app = initializeApp(this.firebaseConfig);
            this.analytics = getAnalytics(app);
            setAnalyticsCollectionEnabled(this.analytics, true);
        }
    }

    public logEvent(event: string, data: object = {}): void
    {
        this.isPlatformBrowser && logEvent(this.analytics, event, data);
    }

    public turnOff(): void
    {
        this.isPlatformBrowser && setAnalyticsCollectionEnabled(this.analytics, false);
    }

    public turnOn(): void
    {
        this.isPlatformBrowser && setAnalyticsCollectionEnabled(this.analytics, true);
    }
}
