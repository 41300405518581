<div [formGroup]="group">
    <input type="file" class="file-input" accept="application/pdf" formControlName="pdf" (change)="onFileSelected($event)" #fileUpload>
    <div class="row g-0">
        <div class="col col-md-auto">
            <span class="montserrat-medium fs-14 s-blue text-decoration-underline me-1 text-link" (click)="fileUpload.click()">
                {{ fileNameHtml || 'INFO_TO_ATTACH.LOAD_FILE' | translate }}
            </span>
        </div>
        <div *ngIf="fileNameHtml" class="col col-md-auto ms-2 d-flex align-items-center remove-icon" (click)="removeFile()">
            <svg-icon src="assets/images/icons/trash.svg"></svg-icon>
        </div>
    </div>
</div>
