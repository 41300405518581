<form [formGroup]="lightingFormGroup" class="container" (ngSubmit)="onSubmit()">
    <div class="row g-0">
        <span class="montserrat-semibold p-blue fs-14 text-wrap" [class.invalid]="areExistingLightCheckboxesInvalid()">
            {{ 'LIGHTING.TITLE' | translate }}*
        </span>
    </div>
    <div class="row g-0">
        <span class="montserrat-italic fs-11 text-wrap sub-title my-3">
            {{ 'LIGHTING.FIRST_STEP' | translate }}
        </span>
    </div>
    <div class="row g-0">
        <div class="col col-6">
            <p>
                <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="existingLightNaturalLight" [class.invalid]="areExistingLightCheckboxesInvalid()">{{ 'LIGHTING.TYPE.NATURAL_LIGHT' | translate }}</mat-checkbox>
            </p>
        </div>
        <div class="col col-6">
            <p>
                <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="existingLightLed" [class.invalid]="areExistingLightCheckboxesInvalid()">{{ 'LIGHTING.TYPE.LED' | translate }}</mat-checkbox>
            </p>
        </div>
        <div class="col col-6">
            <p>
                <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="existingLightFluorescent" [class.invalid]="areExistingLightCheckboxesInvalid()">{{ 'LIGHTING.TYPE.FLUORESCENT' | translate }}</mat-checkbox>
            </p>
        </div>
        <div class="col col-6">
            <p>
                <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="existingLightIncandescent" [class.invalid]="areExistingLightCheckboxesInvalid()">{{ 'LIGHTING.TYPE.INCANDESCENT' | translate }}</mat-checkbox>
            </p>
        </div>
        <div class="col col-6">
            <p>
                <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="existingLightDischarge" [class.invalid]="areExistingLightCheckboxesInvalid()">{{ 'LIGHTING.TYPE.DISCHARGE' | translate }}</mat-checkbox>
            </p>
        </div>
    </div>
    <div class="row g-0 mb-2">
        <span class="montserrat-italic fs-11 text-wrap sub-title">
            {{ 'LIGHTING.SECOND_STEP' | translate }}
        </span>
    </div>
    <app-lighting-source-details *ngFor="let lightSource of optionsSelected(); trackBy: trackyByFormGroupName" [receivedformGroup]="lightingFormGroup.get(lightSource.formGroupName)" [type]="lightSource.type" class="row g-0"></app-lighting-source-details>
    <div class="row g-0 light-control-row">
        <div class="row g-0">
            <span class="montserrat-semibold fs-14 p-blue" [class.invalid]="areRadiosInvalid()">{{ 'LIGHTING.LIGHT_CONTROL' | translate }}*</span>
        </div>
        <div class="row g-0 mt-3">
            <mat-radio-group class="d-flex flex-row justify-content-start" formControlName="radioLightControl" [class.invalid]="areRadiosInvalid()">
                <mat-radio-button class="m-1 col col-2" [value]=true>
                    <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'YES' | translate }}</span>
                </mat-radio-button>
                <mat-radio-button class="m-1 col col-2" [value]=false>
                    <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'NO' | translate }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="canShowLightControlOptions()" class="row g-0 mt-3 ms-2">
            <div class="row g-0">
                <div class="col col-6">
                    <p>
                        <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="lightCtrlTimer" [class.invalid]="areLightControlCheckboxesInvalid()">
                            {{ 'LIGHTING.TIMER' | translate }}
                        </mat-checkbox>
                    </p>
                </div>
                <div class="col col-6">
                    <p>
                        <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="lightCtrlMotionSensor" [class.invalid]="areLightControlCheckboxesInvalid()">
                            {{ 'LIGHTING.MOTION_SENSOR' | translate }}
                        </mat-checkbox>
                    </p>
                </div>
                <div class="col col-6">
                    <p>
                        <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="lightCtrlCrepuscular" [class.invalid]="areLightControlCheckboxesInvalid()">
                            {{ 'LIGHTING.CREPUSCULAR' | translate }}
                        </mat-checkbox>
                    </p>
                </div>
                <div class="col col-6">
                    <p>
                        <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="lightCtrlBrightness" [class.invalid]="areLightControlCheckboxesInvalid()">
                            {{ 'LIGHTING.BRIGHTNESS' | translate }}
                        </mat-checkbox>
                    </p>
                </div>
            </div>
        </div>
        <div class="row g-0 d-flex justify-content-between">
            <div class="col col-md-auto">
                <button type="button" (click)="navigateToPreviousPage()" class="btn secundary-button montserrat-regular">{{ 'PREVIOUS' | translate }}</button>
            </div>
            <div class="col col-md-auto">
                <button type="submit" class="btn primary-button montserrat-regular">{{ 'NEXT' | translate }}</button>
            </div>
        </div>
    </div>
</form>
