<div class="language-switch">
    <app-switch-language [fixed]="false"></app-switch-language>
</div>
<div #infoDiv class="row g-0 h-100">
    <div class="col image-cover">
        <div class="row g-0 h-100 d-flex align-items-center justify-content-center">
            <div class="col col-md-auto">
                <img src="assets/images/homepage/agua.svg" alt="water drop" />
            </div>
            <div class="col col-md-auto margin-x">
                <img src="assets/images/homepage/eletri.svg" alt="eletricity" />
            </div>
            <div class="col col-md-auto">
                <img src="assets/images/homepage/gas.svg" alt="fire" />
            </div>
        </div>
    </div>
    <div class="col d-flex align-items-center justify-content-center">
        <div class="mini-side-bar"></div>
        <div class="info-div">
            <div class="row g-0 mb-4">
                <div class="col d-flex justify-content-center">
                    <img src="assets/images/homepage/qualify-teca-homepage.svg" alt="qualify teca logo" />
                </div>
            </div>
            <div class="row g-0 mb-3">
                <span class="text-center montserrat-semibold fs-16 p-blue">{{ 'HOMEPAGE.TITLE' | translate }}</span>
            </div>
            <div class="row g-0 mb-5">
                <span class="text-center montserrat-medium fs-12 s-black">{{ 'HOMEPAGE.EXPLANATION' | translate }}</span>
            </div>
            <div class="row g-0 d-flex justify-content-center">
                <button (click)="navigateToSimulator()" class="btn primary-button montserrat-regular">{{ 'HOMEPAGE.START' | translate }}</button>
            </div>
        </div>
    </div>
</div>
