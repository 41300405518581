import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable} from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class FileUploaderService
{
    private formData: FormData;

    public constructor(
        @Inject(PLATFORM_ID) private readonly platformId: any,
        private readonly httpClient: HttpClient
    )
    {
        if (isPlatformBrowser(this.platformId))
        {
            this.formData = new FormData();
        }
    }

    public addFile(fileName: string, file: File | Blob): void
    {
        this.formData.append(fileName, file);
    }

    public removeFile(fileName: string): void
    {
        this.formData.delete(fileName);
    }

    public sendEmail(clientEmail: string, clientName: string): Observable<void>
    {
        this.formData.append('user_email', clientEmail);
        this.formData.append('company_person', clientName);

        if (isPlatformBrowser(this.platformId))
        {
            return this.httpClient.post<void>('/email', this.formData);
        }
    }

    public clearFormData(): void
    {
        this.formData = new FormData();
    }
}
