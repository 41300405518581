<form [formGroup]="receivedFormGroup" *ngIf="receivedFormGroup" class="container">
    <div class="row g-0 mb-3">
        <div class="col">
            <svg-icon [src]="getEquipmentIcon()"></svg-icon>
            <span class="montserrat-semibold fs-14 p-blue ms-2">{{ getTranslationKey(translation) | translate }}</span>
        </div>
    </div>
    <div class="row g-0">
        <div class="col me-2" *ngIf="isOtherEquipment">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'ENERGY_EFICIENCY.NAME' | translate }}</mat-label>
                <input class="ps-1" type="string" matInput formControlName="equipmentName">
            </mat-form-field>
        </div>
        <div class="col me-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'ENERGY_EFICIENCY.EXPECTED_POWER' | translate }}</mat-label>
                <input class="ps-1" type="number" matInput formControlName="expectedPower" min="0">
            </mat-form-field>
        </div>
    </div>
    <div class="row g-0">
        <div class="col me-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'ENERGY_EFICIENCY.HOURS_DAILY' | translate }}</mat-label>
                <input class="ps-1" type="number" matInput formControlName="hoursPerDay" min="0">
            </mat-form-field>
        </div>
        <div class="col ms-2 me-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'ENERGY_EFICIENCY.DAYS_YEARLY' | translate }}</mat-label>
                <input class="ps-1" type="number" matInput formControlName="daysPerYear" min="0">
            </mat-form-field>
        </div>
        <div class="col ms-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'ENERGY_EFICIENCY.NUMBER_OF_DEVICES' | translate }}</mat-label>
                <input class="ps-1" type="number" matInput formControlName="numberOfDevices" min="0">
            </mat-form-field>
        </div>
    </div>
</form>
