import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EnergyRating} from '@domain/energy-rating.enum';
import {EnergySource} from '@domain/energy-source.enum';
import {Period} from '@domain/period.enum';
import {FileUploaderService} from '@shared/file-uploader.service';
import {isEqual} from 'lodash';

@Component({
    selector: 'app-info-to-attach',
    templateUrl: './info-to-attach.component.html',
    styleUrls: ['./info-to-attach.component.scss']
})
export class InfoToAttachComponent implements OnInit
{
    @Output() public navigateBack = new EventEmitter<FormGroup>();
    @Output() public navigateForward = new EventEmitter<FormGroup>();

    public energySources =
        [
            `INFO_TO_ATTACH.${EnergySource.ELETRICITY}`,
            `INFO_TO_ATTACH.${EnergySource.LPG_GAS}`,
            `INFO_TO_ATTACH.${EnergySource.NATURAL_GAS}`,
            `INFO_TO_ATTACH.${EnergySource.OTHER}`
        ];

    public energeticRatings =
        [
            EnergyRating.A_3PLUS,
            EnergyRating.A_2PLUS,
            EnergyRating.A_PLUS,
            EnergyRating.A,
            EnergyRating.B,
            EnergyRating.C,
            EnergyRating.D,
            EnergyRating.E,
            EnergyRating.F,
            EnergyRating.G
        ];

    public infoToAttachGroup = new FormGroup({
        lpgGas: new FormControl(''),
        naturalGas: new FormControl(''),
        other: new FormControl(''),
        radioAudit: new FormControl('', Validators.required),
        auditArray: this.fb.array([]),
        eletricityFormGroup: new FormGroup({
            consumption: new FormControl('', [Validators.required, Validators.min(0)]),
            ciclos: new FormControl(''),
            numberOfPeriods: new FormControl('', [Validators.required]),
            tensionLevel: new FormControl('', [Validators.required]),
            deliveryPointCode: new FormControl('', [Validators.minLength(18), Validators.maxLength(18)]),
            pdf: new FormControl('')
        }),
        naturalGasFormGroup: new FormGroup({
            instalationUniversalCode: new FormControl('', [Validators.minLength(18), Validators.maxLength(18)]),
            supplyPressureLevel: new FormControl(''),
            naturalGasConsumption: new FormControl('', [Validators.min(0)]),
            naturalGasCost: new FormControl('', [Validators.min(0)]),
            pdf: new FormControl('')
        }),
        lpgGasFormGroup: new FormGroup({
            lgpGasConsumption: new FormControl('', [Validators.min(0)]),
            lgpGasCost: new FormControl('', [Validators.min(0)]),
            pdf: new FormControl('')
        }),
        otherFormGroup: new FormGroup({
            otherArray: this.fb.array([]),
            pdf: new FormControl('')
        })
    });

    private nextButtonPressed = false;

    public constructor(private readonly fb: FormBuilder, private readonly uploaderService: FileUploaderService)
    { }

    public ngOnInit(): void
    {
        this.infoToAttachGroup.get('other').valueChanges.subscribe((value) =>
        {
            if (!value)
            {
                this.cleanFormArray('otherFormGroup.otherArray');
            }
        });

        this.infoToAttachGroup.get('radioAudit').valueChanges.subscribe((value) =>
        {
            value ? this.addNewAudit() : this.cleanFormArray('auditArray');
        });
    }

    public onSubmit(): void
    {
        this.checkUpdatedConsumptionInput();

        this.nextButtonPressed = true;

        if (this.infoToAttachGroup.valid)
        {
            window.scrollTo({top: 0});
            this.navigateForward.emit(this.infoToAttachGroup);
        }
        else
        {
            this.infoToAttachGroup.markAllAsTouched();
        }
    }

    public checkUpdatedConsumptionInput(): void
    {
        const electricityFormGroup = this.infoToAttachGroup.get('eletricityFormGroup');
        let consumption = 0;
        let consumptionInputFields = [];
        if (electricityFormGroup.get('numberOfPeriods').value === Period.SINGLE)
        {
            consumptionInputFields.push(electricityFormGroup.get('singlePeriodFormGroup').get('consumption'));

            consumption = +consumptionInputFields[0].value;
        }
        else if (electricityFormGroup.get('numberOfPeriods').value === Period.BI_SCHEDULE)
        {
            const emptyOutPeriodFormGroup = electricityFormGroup.get('emptyOutPeriodFormGroup').get('consumption');
            const emptyPeriodFormGroup = electricityFormGroup.get('emptyPeriodFormGroup').get('consumption');

            consumptionInputFields.push(emptyOutPeriodFormGroup, emptyPeriodFormGroup);

            consumption = +emptyOutPeriodFormGroup.value
                + +emptyPeriodFormGroup.value;
        }
        else if (electricityFormGroup.get('numberOfPeriods').value === Period.TRI_SCHEDULE)
        {
            const fullPeriodFormGroup = electricityFormGroup.get('fullPeriodFormGroup').get('consumption');
            const emptyPeriodFormGroup = electricityFormGroup.get('emptyPeriodFormGroup').get('consumption');
            const pontaPeriodFormGroup = electricityFormGroup.get('pontaPeriodFormGroup').get('consumption');

            consumptionInputFields.push(fullPeriodFormGroup, emptyPeriodFormGroup, pontaPeriodFormGroup);

            consumption = +fullPeriodFormGroup.value
                + +emptyPeriodFormGroup.value
                + +pontaPeriodFormGroup.value;
        }
        else if (electricityFormGroup.get('numberOfPeriods').value === Period.TETRA_SCHEDULE)
        {
            const fullPeriodFormGroup = electricityFormGroup.get('fullPeriodFormGroup').get('consumption');
            const emptyPeriodFormGroup = electricityFormGroup.get('emptyPeriodFormGroup').get('consumption');
            const pontaPeriodFormGroup = electricityFormGroup.get('pontaPeriodFormGroup').get('consumption');
            const superEmptyPeriodFormGroup = electricityFormGroup.get('superEmptyPeriodFormGroup').get('consumption');

            consumptionInputFields.push(fullPeriodFormGroup, emptyPeriodFormGroup, pontaPeriodFormGroup, superEmptyPeriodFormGroup);

            consumption = +fullPeriodFormGroup.value
                + +emptyPeriodFormGroup.value
                + +pontaPeriodFormGroup.value
                + +superEmptyPeriodFormGroup.value;
        }

        if (!(consumption === +electricityFormGroup.get('consumption').value))
        {
            consumptionInputFields.forEach((inputField) =>
            {
                inputField.setErrors({'incorrect': true});
            });
        }
        else
        {
            consumptionInputFields.forEach((inputField) =>
            {
                inputField.updateValueAndValidity();
            });
        }
    }

    public navigateToPreviousPage(): void
    {
        window.scrollTo({top: 0});
        this.navigateBack.emit(this.infoToAttachGroup);
    }

    public isChecked(source: string): boolean
    {
        return this.infoToAttachGroup.get(source).value;
    }

    public areRadiosInvalid(): boolean
    {
        return this.nextButtonPressed && this.infoToAttachGroup.get('radioAudit').invalid;
    }

    public canShowCertificateDetails(): boolean
    {
        return this.infoToAttachGroup.get('radioAudit').value;
    }

    public get audits(): FormArray
    {
        return this.infoToAttachGroup.get('auditArray') as FormArray;
    }

    public addNewAudit(): void
    {
        this.audits.push(
            this.fb.group({
                energyCertificateNumber: new FormControl('', Validators.required),
                energeticRating: new FormControl('', Validators.required)
            })
        );
    }

    public removeAudit(index: number): void
    {
        if (this.canRemoveAudit())
        {
            this.audits.removeAt(index);
        }
    }

    public canRemoveAudit(): boolean
    {
        return !isEqual(this.audits.controls.length, 1);
    }

    private cleanFormArray(array: string): void
    {
        for (let i = (this.infoToAttachGroup.get(array) as FormArray).length - 1; i >= 0; i--)
        {
            (this.infoToAttachGroup.get(array) as FormArray).removeAt(i);
        }
    }
}
