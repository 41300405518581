<form [formGroup]="infoToAttachGroup" (ngSubmit)="onSubmit()">
    <div class="row g-0">
        <span class="montserrat-semibold p-blue fs-14 text-wrap">
            {{ 'INFO_TO_ATTACH.TITLE' | translate }}
        </span>
    </div>
    <div class="row g-0">
        <span class="montserrat-italic fs-11 text-wrap sub-title my-3">
            {{ 'INFO_TO_ATTACH.FIRST_STEP' | translate }}
        </span>
    </div>
    <div class="row g-0">
        <div class="col col-6">
            <p>
                <mat-checkbox class="montserrat-medium fs-14 s-black" checked disabled>{{ 'INFO_TO_ATTACH.ELETRICITY' | translate }}</mat-checkbox>
            </p>
        </div>
        <div class="col col-6">
            <p>
                <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="lpgGas">{{ 'INFO_TO_ATTACH.LPG_GAS' | translate }}</mat-checkbox>
            </p>
        </div>
        <div class="col col-6">
            <p>
                <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="naturalGas">{{ 'INFO_TO_ATTACH.NATURAL_GAS' | translate }}</mat-checkbox>
            </p>
        </div>
        <div class="col col-6">
            <p>
                <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="other">{{ 'INFO_TO_ATTACH.OTHER' | translate }}</mat-checkbox>
            </p>
        </div>
    </div>
    <div class="row g-0 mb-2">
        <span class="montserrat-italic fs-11 text-wrap sub-title mt-3">
            {{ 'INFO_TO_ATTACH.SECOND_STEP' | translate }}
        </span>
    </div>
    <div class="row g-0">
        <app-eletricity [eletricityFormGroup]="infoToAttachGroup.get('eletricityFormGroup')"></app-eletricity>
    </div>
    <div *ngIf="isChecked('naturalGas')" class="row g-0">
        <app-natural-gas [naturalGasFormGroup]="infoToAttachGroup.get('naturalGasFormGroup')"></app-natural-gas>
    </div>
    <div *ngIf="isChecked('lpgGas')" class="row g-0">
        <app-lpg-gas [lpgGasFormGroup]="infoToAttachGroup.get('lpgGasFormGroup')"></app-lpg-gas>
    </div>
    <div *ngIf="isChecked('other')" class="row g-0">
        <app-other [otherFormGroup]="infoToAttachGroup.get('otherFormGroup')"></app-other>
    </div>
    <div class="row g-0 audits-row">
        <div class="row g-0">
            <span class="montserrat-semibold fs-14 p-blue ms-2" [class.invalid]="areRadiosInvalid()">{{ 'INFO_TO_ATTACH.PREVIOUS_AUDITS' | translate }}*</span>
        </div>
        <div class="row g-0 mt-3">
            <mat-radio-group class="d-flex flex-row justify-content-start" formControlName="radioAudit" [class.invalid]="areRadiosInvalid()">
                <mat-radio-button class="m-1 col col-2" [value]=true>
                    <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'YES' | translate }}</span>
                </mat-radio-button>
                <mat-radio-button class="m-1 col col-2" [value]=false>
                    <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'NO' | translate }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div [hidden]="!canShowCertificateDetails()" class="row g-0 mt-3">
            <ng-container *ngFor="let audit of audits.controls; let i = index">
                <div [formGroup]="audit" class="row g-0">
                    <div class="col me-2">
                        <mat-form-field appearance="standard">
                            <mat-label>{{ 'INFO_TO_ATTACH.ENERGY_CERTIFICATE_NUMBER' | translate }}</mat-label>
                            <input class="ps-1" type="text" matInput formControlName="energyCertificateNumber">
                        </mat-form-field>
                    </div>
                    <div class="col col-4 ms-2">
                        <mat-form-field appearance="standard">
                            <mat-label>{{ 'INFO_TO_ATTACH.RATING' | translate }}</mat-label>
                            <mat-select formControlName="energeticRating">
                                <mat-option *ngFor="let rating of energeticRatings" [value]="rating" class="montserrat-medium fs-14 s-black energy-droplist">
                                    {{ rating }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col col-md-auto ms-2 d-flex align-items-center remove-icon" [class.disabled]="!canRemoveAudit()" (click)="removeAudit(i)">
                        <svg-icon src="assets/images/icons/trash.svg"></svg-icon>
                    </div>
                </div>
            </ng-container>
            <div [hidden]="!canShowCertificateDetails()" class="row g-0 mt-2 d-flex justify-content-end">
                <div class="col col-md-auto">
                    <span class="montserrat-medium fs-14 s-blue text-decoration-underline me-1 text-link" (click)="addNewAudit()">{{ 'ADD' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row g-0 d-flex justify-content-between">
        <div class="col col-md-auto">
            <button type="button" (click)="navigateToPreviousPage()" class="btn secundary-button montserrat-regular">{{ 'PREVIOUS' | translate }}</button>
        </div>
        <div class="col col-md-auto">
            <button type="submit" class="btn primary-button montserrat-regular">{{ 'NEXT' | translate }}</button>
        </div>
    </div>
</form>
