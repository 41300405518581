import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Language} from '@domain/lang.enum';
import {forkJoin} from 'rxjs';
import {FirebaseService} from './firebase.service';

/* eslint-disable @typescript-eslint/naming-convention */
@Injectable({
    providedIn: 'root'
})
export class CookieConsentService
{
    private readonly cookieconsent: any;
    private readonly isPlatformBrowser: boolean;

    public constructor(
        @Inject(PLATFORM_ID) private readonly platformId: any,
        private readonly httpClient: HttpClient,
        private readonly firebaseService: FirebaseService
    )
    {
        this.isPlatformBrowser = isPlatformBrowser(this.platformId);

        if (this.isPlatformBrowser)
        {
            this.cookieconsent = (window as any).initCookieConsent();
        }
    }

    public run(currentLanguage: Language, possible: Language[]): void
    {
        if (this.isPlatformBrowser)
        {
            const config = {
                autorun: true,
                current_lang: currentLanguage,
                autoclear_cookies: true,
                page_scripts: true,
                gui_options: {
                    consent_modal: {
                        layout: 'box',
                        position: 'bottom left',
                        transition: 'slide',
                        swap_buttons: false
                    }
                },
                onAccept: (): void => this.toggleAnalytics(),
                onChange: (): void => this.toggleAnalytics(),
                languages: {}
            };

            forkJoin(possible.map((language: Language) => this.httpClient.get(`./assets/i18n/${language}.json`))).subscribe((translations: any[]) =>
            {
                config.languages = possible.reduce((languagesConfiguration: any, language: Language, index: number) =>
                {
                    languagesConfiguration[language] = this.createConfigForLanguage(translations[index]);

                    return languagesConfiguration;
                }, {});

                this.cookieconsent.run(config);
            });
        }
    }

    private createConfigForLanguage(translations: any): any
    {
        return {
            consent_modal: {
                description: translations.COOKIES.CONSENT_MODAL.DESCRIPTION,
                primary_btn: {
                    text: translations.COOKIES.CONSENT_MODAL.PRIMARY_BUTTON_TEXT,
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: translations.COOKIES.CONSENT_MODAL.SECONDARY_BUTTON_TEXT,
                    role: 'settings'
                }
            },
            settings_modal: {
                title: translations.COOKIES.SETTINGS_MODAL.TITLE,
                save_settings_btn: translations.COOKIES.SETTINGS_MODAL.SAVE_SETTINGS_BUTTON,
                accept_all_btn: translations.COOKIES.SETTINGS_MODAL.ACCEPT_ALL_BUTTON,
                blocks: [
                    {}, {
                        title: translations.COOKIES.SETTINGS_MODAL.BLOCKS.NECESSARY_TITLE,
                        description: translations.COOKIES.SETTINGS_MODAL.BLOCKS.NECESSARY_DESCRIPTION,
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true
                        }
                    }, {
                        title: translations.COOKIES.SETTINGS_MODAL.BLOCKS.ANALYTICS_TITLE,
                        description: translations.COOKIES.SETTINGS_MODAL.BLOCKS.ANALYTICS_DESCRIPTION,
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false
                        }
                    }
                ]
            }
        };
    }

    public updateLanguage(language: Language): void
    {
        this.cookieconsent.updateLanguage(language as string);
    }

    public isGoogleAnalyticsEnabled(): boolean
    {
        return this.cookieconsent.allowedCategory('analytics');
    }

    private toggleAnalytics(): void
    {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.isGoogleAnalyticsEnabled() ? this.firebaseService.turnOn() : this.firebaseService.turnOff();
    }
}
