import {isPlatformBrowser} from '@angular/common';
import {Component, Inject, OnDestroy, PLATFORM_ID} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Language} from '@domain/lang.enum';
import {TranslateService} from '@ngx-translate/core';
import {CookieConsentService} from '@shared/cookie-consent.service';
import {isEqual} from 'lodash';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy
{
    public lang = '';
    private globalUrl = '';
    private readonly routeSubscription: Subscription;
    private readonly isPlatformBrowser: boolean;

    public constructor(
        private readonly translate: TranslateService,
        private readonly cookiesService: CookieConsentService,
        private readonly router: Router,
        @Inject(PLATFORM_ID) private readonly platformId: any
    )
    {
        let firstNavigation = true;
        this.translate.addLangs([Language.EN, Language.PT]);

        this.isPlatformBrowser = isPlatformBrowser(this.platformId);

        this.routeSubscription = this.router.events.subscribe((event: any) =>
        {
            if (event instanceof NavigationStart && firstNavigation)
            {
                this.globalUrl = event.url;

                if (isEqual(this.globalUrl, '/'))
                {
                    this.lang = this.getLanguageBrowser();
                    this.globalUrl = `/${this.lang}/homepage`;
                    this.router.navigateByUrl(this.globalUrl);
                }
                else
                {
                    this.lang = this.globalUrl.split('/')[1];
                    if (!isEqual(this.lang, Language.PT) && !isEqual(this.lang, Language.EN))
                    {
                        this.lang = Language.EN;
                    }
                }

                this.translate.setDefaultLang(this.lang);
                this.translate.use(this.lang);
                this.cookiesService.run(this.lang as Language, this.translate.getLangs() as Language[]);

                firstNavigation = false;
            }

        });
    }

    private getLanguageBrowser(): string
    {
        let lang;

        if (this.isPlatformBrowser)
        {
            if (navigator.language)
            {
                lang = navigator.language.includes(Language.PT) ? Language.PT : Language.EN;

                lang = lang.includes(Language.PT) ? Language.PT : Language.EN;
            }
            else
            {
                lang = Language.EN;
            }
        }

        return lang;
    }

    public ngOnDestroy(): void
    {
        if (this.routeSubscription)
        {
            this.routeSubscription.unsubscribe();
        }
    }
}
