import {Component, Input} from '@angular/core';
import {Language} from '@domain/lang.enum';
import {TranslateService} from '@ngx-translate/core';
import {CookieConsentService} from '@shared/cookie-consent.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent
{
    @Input() public language: string;
    
    public constructor(private readonly cookiesService: CookieConsentService, private readonly translate: TranslateService)
    { }

    public navigateToLoad(): void
    {
        window.open('https://load.digital/', '_blank');
    }

    public triggerCookies(): void
    {
        this.cookiesService.run(this.language as Language, this.translate.getLangs() as Language[]);
    }
}
