import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-renewables-and-energy-generation',
    templateUrl: './renewables-and-energy-generation.component.html',
    styleUrls: ['./renewables-and-energy-generation.component.scss']
})
export class RenewablesAndEnergyGenerationComponent implements OnInit
{
    @Output() public navigateBack = new EventEmitter<FormGroup>();
    @Output() public navigateForward = new EventEmitter<FormGroup>();

    public renewablesFormGroup = new FormGroup({
        radioRenewable: new FormControl('', Validators.required),
        photovoltaic: new FormControl(false),
        cogeneration: new FormControl(false),
        thermal: new FormControl(false),
        windEnergy: new FormControl(false),
        renewableCapacity: new FormControl('')
    });

    private nextButtonPressed = false;

    public ngOnInit(): void
    {
        this.renewablesFormGroup.get('radioRenewable').valueChanges.subscribe((value) =>
        {
            if (value)
            {
                this.renewablesFormGroup.get('renewableCapacity').setValidators(Validators.required);
            }
            else
            {
                this.renewablesFormGroup.get('renewableCapacity').clearValidators();
                this.renewablesFormGroup.get('renewableCapacity').updateValueAndValidity();
            }
        });
    }

    public onSubmit(): void
    {
        this.nextButtonPressed = true;
        if (this.renewablesFormGroup.valid && !this.areCheckboxesInvalid())
        {
            window.scrollTo({top: 0});
            this.navigateForward.emit(this.renewablesFormGroup);
        }
    }

    public navigateToPreviousPage(): void
    {
        window.scrollTo({top: 0});
        this.navigateBack.emit(this.renewablesFormGroup);
    }

    public areCheckboxesInvalid(): boolean
    {
        return this.renewablesFormGroup.get('radioRenewable').value && this.nextButtonPressed
            && !this.renewablesFormGroup.get('photovoltaic').value
            && !this.renewablesFormGroup.get('cogeneration').value
            && !this.renewablesFormGroup.get('thermal').value
            && !this.renewablesFormGroup.get('windEnergy').value;
    }

    public areRadiosInvalid(): boolean
    {
        return this.nextButtonPressed && this.renewablesFormGroup.get('radioRenewable').invalid;
    }

    public canShowRenewableOptions(): boolean
    {
        return this.renewablesFormGroup.get('radioRenewable').value;
    }
}
