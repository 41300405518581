<form [formGroup]="lpgGasFormGroup" class="container" (ngSubmit)="onSubmit()">
    <div class="row g-0 mb-3">
        <div class="col">
            <svg-icon src="assets/images/icons/gas.svg"></svg-icon>
            <span class="montserrat-semibold fs-14 p-blue ms-2">{{ 'INFO_TO_ATTACH.LPG_GAS_PART.LPG_GAS_TON' | translate }}</span>
        </div>
        <div class="col col-md-auto">
            <app-file-uploader type="glp_gas" [group]="lpgGasFormGroup"></app-file-uploader>
        </div>
    </div>
    <div class="row g-0 mb-2">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'INFO_TO_ATTACH.NATURAL_GAS_PART.NATURAL_GAS_CONSUMPTION' | translate }}</mat-label>
            <input class="ps-1" type="number" matInput formControlName="lgpGasConsumption" min="0">
        </mat-form-field>
    </div>
    <div class="row g-0">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'INFO_TO_ATTACH.NATURAL_GAS_PART.NATURAL_GAS_COST' | translate }}</mat-label>
            <input class="ps-1" type="number" matInput formControlName="lgpGasCost" min="0">
            <img src="assets/images/icons/euro.svg" matSuffix alt="euro_icon" />
        </mat-form-field>
    </div>
</form>
