import {find, isEqual, isNumber, reject} from 'lodash';

export class EnumUtils
{
    public static getKeyFromValue<E>(enumObject: E, value: string | number): string
    {
        return find(
            reject(Object.keys(enumObject), (key: string) => typeof enumObject[key] !== 'string' && !isNumber(enumObject[key])),
            (key: string) => isEqual(enumObject[key], value)
        );
    }
}
