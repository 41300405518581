import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomModalComponent} from './custom-modal/custom-modal.component';

@Injectable()
export class ModalService
{
    public constructor(private readonly modalService: NgbModal)
    { }

    public openModal(): Promise<any>
    {
        const modalRef = this.modalService.open(CustomModalComponent, {backdropClass: 'custom-modal-background', centered: true});
        
        return modalRef.result;
    }
}
