/* eslint-disable @typescript-eslint/no-unused-expressions */
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DaysWeek} from '@domain/days-week.enum';
import {UsageProfileUnits} from '@domain/usage-profile-units.enum';
import {TranslateService} from '@ngx-translate/core';
import {EnumUtils} from '@shared/enum-utils';
import {isEqual, range} from 'lodash';

@Component({
    selector: 'app-usage-profile',
    templateUrl: './usage-profile.component.html',
    styleUrls: ['./usage-profile.component.scss']
})
export class UsageProfileComponent implements OnInit
{
    @Output() public navigateBack = new EventEmitter<FormGroup>();
    @Output() public navigateForward = new EventEmitter<FormGroup>();

    public units =
        [
            EnumUtils.getKeyFromValue(UsageProfileUnits, UsageProfileUnits.LITERS),
            EnumUtils.getKeyFromValue(UsageProfileUnits, UsageProfileUnits.M3),
            EnumUtils.getKeyFromValue(UsageProfileUnits, UsageProfileUnits.TONS)
        ];

    public weekWorkPeriods =
        [
            {translation: 'USAGE_PROFILE.SINGLE_SCHEDULE_WEEK', value: 'single'},
            {translation: 'USAGE_PROFILE.EXCEPTIONS_SCHEDULE_WEEK', value: 'exception'},
            {translation: 'USAGE_PROFILE.DAILY_SCHEDULE_WEEK', value: 'daily'}
        ];

    public weekendWorkPeriods =
        [
            {translation: 'USAGE_PROFILE.SINGLE_SCHEDULE_WEEKEND', value: 'single'},
            {translation: 'USAGE_PROFILE.DAILY_SCHEDULE_WEEKEND', value: 'daily'},
            {translation: 'USAGE_PROFILE.NO_SCHEDULE', value: 'none'}
        ];

    public weekDays =
        [
            EnumUtils.getKeyFromValue(DaysWeek, DaysWeek.MONDAY),
            EnumUtils.getKeyFromValue(DaysWeek, DaysWeek.TUESDAY),
            EnumUtils.getKeyFromValue(DaysWeek, DaysWeek.WEDNESDAY),
            EnumUtils.getKeyFromValue(DaysWeek, DaysWeek.THURSDAY),
            EnumUtils.getKeyFromValue(DaysWeek, DaysWeek.FRIDAY)
        ];

    public shiftsDropdownValues = range(3).map((index: number) => index + 1);

    public weekendShifts = [this.translate.instant('NOT_APPLICABLE'), ...this.shiftsDropdownValues];

    public usageProfileFormGroup = new FormGroup({
        radioWeekOperationPeriod: new FormControl('', Validators.required),
        radioWeekendOperationPeriod: new FormControl('', Validators.required),
        employeeNumber: new FormControl(''),
        itemsProduced: new FormControl('', Validators.required),
        itemsProducedUnits: new FormControl('', Validators.required),
        extendedStops: this.fb.array([])
    });

    public sundayShiftDefault = this.translate.instant('NOT_APPLICABLE');
    public saturdayShiftDefault = 1;
    public weekShiftDefault = 1;

    private nextButtonPressed = false;

    public constructor(private readonly fb: FormBuilder, private readonly translate: TranslateService)
    {
        this.addExtendedStop();
    }

    public ngOnInit(): void
    {
        this.usageProfileFormGroup.get('radioWeekOperationPeriod').valueChanges.subscribe((value) =>
        {
            isEqual(value, 'single') ? this.usageProfileFormGroup.addControl('singleScheduleFormGroup', this.createSingleScheduleFormGroup()) : this.usageProfileFormGroup.removeControl('singleScheduleFormGroup');

            isEqual(value, 'exception') ? this.usageProfileFormGroup.addControl('exceptionScheduleFormGroup', this.createExceptionFormGroup()) : this.usageProfileFormGroup.removeControl('exceptionScheduleFormGroup');

            isEqual(value, 'daily') ? this.usageProfileFormGroup.addControl('dailyScheduleFormGroup', this.createDailyFormGroup()) : this.usageProfileFormGroup.removeControl('dailyScheduleFormGroup');
        });

        this.usageProfileFormGroup.get('radioWeekendOperationPeriod').valueChanges.subscribe((value) =>
        {
            isEqual(value, 'single') ? this.usageProfileFormGroup.addControl('weekendSingleScheduleFormGroup', this.createSingleScheduleFormGroup()) : this.usageProfileFormGroup.removeControl('weekendSingleScheduleFormGroup');

            isEqual(value, 'daily') ? this.usageProfileFormGroup.addControl('weekendDailyScheduleFormGroup', this.createWeekendDailyFormGroup()) : this.usageProfileFormGroup.removeControl('weekendDailyScheduleFormGroup');
        });
    }

    public areRadiosInvalid(controlName: string): boolean
    {
        return this.nextButtonPressed && this.usageProfileFormGroup.get(controlName).invalid;
    }

    public isSelected(type: 'week' | 'weekend', radio: string): boolean
    {
        return isEqual(type, 'week')
            ? isEqual(this.usageProfileFormGroup.get('radioWeekOperationPeriod').value, radio)
            : isEqual(this.usageProfileFormGroup.get('radioWeekendOperationPeriod').value, radio);
    }

    public getUnitTranslation(unit: UsageProfileUnits): string
    {
        return `UNITS.${unit}`;
    }

    public getWeekDayTranslation(day: DaysWeek): string
    {
        return `WEEKDAYS.${day}`;
    }

    public get extendedStops(): FormArray
    {
        return this.usageProfileFormGroup.controls.extendedStops as FormArray;
    }

    public getStartDateValue(form: FormGroup): Date
    {
        return form.get('start').value;
    }

    public getEndDateValue(form: FormGroup): Date
    {
        return form.get('end').value;
    }

    public canAddStop(): boolean
    {
        return this.extendedStops.controls.length < 4;
    }

    public addExtendedStop(): void
    {
        if (this.canAddStop())
        {
            const extendedStopForm = this.fb.group({
                start: new FormControl('', Validators.required),
                end: new FormControl('', Validators.required)
            });

            this.extendedStops.push(extendedStopForm);
        }
    }

    public removeExtendedStop(index: number): void
    {
        if (this.canRemoveStop())
        {
            this.extendedStops.removeAt(index);
        }
    }

    public canRemoveStop(): boolean
    {
        return !isEqual(this.extendedStops.controls.length, 1);
    }

    private createShift(): FormGroup
    {
        return this.fb.group({
            startTime: new FormGroup({
                hour: new FormControl('', Validators.required),
                minute: new FormControl('', Validators.required)
            }),
            endTime: new FormGroup({
                hour: new FormControl('', Validators.required),
                minute: new FormControl('', Validators.required)
            })
        });
    }

    private createException(): FormGroup
    {
        return this.fb.group({
            dayOfTheWeek: new FormControl('1', Validators.required),
            numberOfShifts: new FormControl('1', Validators.required),
            shifts: this.fb.array([
                this.createShift()
            ])
        });
    }

    public addException(): void
    {
        const exception = this.createException();

        (this.usageProfileFormGroup.get('exceptionScheduleFormGroup.exceptions') as FormArray).push(exception);
    }

    public removeException(index: number): void
    {
        (this.usageProfileFormGroup.get('exceptionScheduleFormGroup.exceptions') as FormArray).removeAt(index);
    }

    public datesInvalid(): boolean
    {
        return this.extendedStops.invalid && this.extendedStops.touched && this.nextButtonPressed;
    }

    private createSingleScheduleFormGroup(): FormGroup
    {
        return this.fb.group({
            numberOfShifts: new FormControl('1', Validators.required),
            shifts: this.fb.array([
                this.createShift()
            ])
        });
    }

    private createSingleScheduleFormGroupEmptyShifts(): FormGroup
    {
        return this.fb.group({
            numberOfShifts: new FormControl('0', Validators.required),
            shifts: this.fb.array([
            ])
        });
    }

    private createExceptionFormGroup(): FormGroup
    {
        return this.fb.group({
            numberOfShifts: new FormControl('1', Validators.required),
            shifts: this.fb.array([
                this.createShift()
            ]),
            exceptions: this.fb.array([
                this.createException()
            ])
        });
    }

    private createDailyFormGroup(): FormGroup
    {
        return this.fb.group({
            monday: this.createSingleScheduleFormGroup(),
            tuesday: this.createSingleScheduleFormGroup(),
            wednesday: this.createSingleScheduleFormGroup(),
            thursday: this.createSingleScheduleFormGroup(),
            friday: this.createSingleScheduleFormGroup()
        });
    }

    private createWeekendDailyFormGroup(): FormGroup
    {
        return this.fb.group({
            saturday: this.createSingleScheduleFormGroup(),
            sunday: this.createSingleScheduleFormGroupEmptyShifts()
        });
    }

    public navigateToPreviousPage(): void
    {
        window.scrollTo({top: 0});
        this.navigateBack.emit(this.usageProfileFormGroup);
    }

    public onSubmit(): void
    {
        this.nextButtonPressed = true;
        if (this.usageProfileFormGroup.valid)
        {
            window.scrollTo({top: 0});
            this.navigateForward.emit(this.usageProfileFormGroup);
        }
        else
        {
            this.usageProfileFormGroup.markAllAsTouched();
        }
    }
}
