<form [formGroup]="eletricityFormGroup" class="container">
    <div class="row g-0 mb-3">
        <div class="col">
            <svg-icon src="assets/images/icons/eletricidade.svg"></svg-icon>
            <span class="montserrat-semibold fs-14 p-blue ms-2">{{ 'INFO_TO_ATTACH.ELETRICITY_PART.ELETRICITY_KWH' | translate }}</span>
        </div>
        <div class="col col-md-auto">
            <app-file-uploader type="eletricity" [group]="eletricityFormGroup"></app-file-uploader>
        </div>
    </div>
    <div class="row g-0 mb-2">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'INFO_TO_ATTACH.ELETRICITY_PART.CONSUMPTION_EX' | translate }}</mat-label>
            <input class="ps-1" type="number" matInput formControlName="consumption" min="0">
        </mat-form-field>
    </div>
    <div class="row g-0 mb-2">
        <div class="col me-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'INFO_TO_ATTACH.ELETRICITY_PART.CICLES' | translate }}</mat-label>
                <mat-select formControlName="ciclos">
                    <mat-option *ngFor="let cicle of cicles" [value]="cicle.value" class="montserrat-medium fs-14 s-black">
                        {{ cicle.translation | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col ms-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'INFO_TO_ATTACH.ELETRICITY_PART.NUMBER_OF_PERIODS' | translate }}</mat-label>
                <mat-select formControlName="numberOfPeriods">
                    <mat-option *ngFor="let period of numberOfPeriods" [value]="period" class="montserrat-medium fs-14 s-black">
                        {{ getPeriodTranslation(period) | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <ng-container *ngIf="periodSelected('SINGLE')">
        <app-price-consumption type="SINGLE" [form]="eletricityFormGroup.get('singlePeriodFormGroup')"></app-price-consumption>
    </ng-container>
    <ng-container *ngIf="periodSelected('BI_SCHEDULE')">
        <app-price-consumption type="EMPTY" [form]="eletricityFormGroup.get('emptyPeriodFormGroup')"></app-price-consumption>
        <app-price-consumption type="EMPTY_OUT" [form]="eletricityFormGroup.get('emptyOutPeriodFormGroup')"></app-price-consumption>
    </ng-container>
    <ng-container *ngIf="periodSelected('TRI_SCHEDULE')">
        <app-price-consumption type="PONTA" [form]="eletricityFormGroup.get('pontaPeriodFormGroup')"></app-price-consumption>
        <app-price-consumption type="FULL" [form]="eletricityFormGroup.get('fullPeriodFormGroup')"></app-price-consumption>
        <app-price-consumption type="EMPTY" [form]="eletricityFormGroup.get('emptyPeriodFormGroup')"></app-price-consumption>
    </ng-container>
    <ng-container *ngIf="periodSelected('TETRA_SCHEDULE')">
        <app-price-consumption type="PONTA" [form]="eletricityFormGroup.get('pontaPeriodFormGroup')"></app-price-consumption>
        <app-price-consumption type="FULL" [form]="eletricityFormGroup.get('fullPeriodFormGroup')"></app-price-consumption>
        <app-price-consumption type="EMPTY" [form]="eletricityFormGroup.get('emptyPeriodFormGroup')"></app-price-consumption>
        <app-price-consumption type="SUPER_EMPTY" [form]="eletricityFormGroup.get('superEmptyPeriodFormGroup')"></app-price-consumption>
    </ng-container>
    <div class="row g-0 mb-2">
        <div class="row g-0">
            <span class="montserrat-semibold fs-14 p-blue" [class.invalid]="areRadiosInvalid()">{{ 'INFO_TO_ATTACH.ELETRICITY_PART.TENSION_LEVEL' | translate }}*</span>
        </div>
        <div class="row g-0 mt-1">
            <mat-radio-group class="d-flex flex-row justify-content-around" formControlName="tensionLevel" [class.invalid]="areRadiosInvalid()">
                <mat-radio-button class="m-1 d-flex align-items-start" *ngFor="let option of tensionLevelOptions" [value]="option">
                    <span class="montserrat-regular ts-14 s-black text-wrap">{{option}}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="row g-0 mb-2">
        <div class="col col-md-auto me-4 d-flex align-items-center">
            <span class="montserrat-semibold p-blue fs-16">PT</span>
        </div>
        <div class="col">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'INFO_TO_ATTACH.ELETRICITY_PART.DELIVERY_POINT_CODE' | translate }}</mat-label>
                <input class="ps-1" type="text" matInput formControlName="deliveryPointCode">
            </mat-form-field>
        </div>
    </div>
</form>
