<form [formGroup]="receivedformGroup" class="container my-3">
    <span class="montserrat-medium ts-14 s-black">{{ translationTitle | translate }}</span>
    <div class="row g-0 mt-2">
        <div class="col me-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'LIGHTING.LIGHT_TYPE' | translate }}</mat-label>
                <mat-select formControlName="lightType">
                    <mat-option *ngFor="let type of getTypes()" [value]="type" class="montserrat-medium fs-14 s-black energy-droplist">
                        {{ 'LIGHTING.TYPE.' + type | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col ms-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'LIGHTING.NUMBER_OF_LIGHTS' | translate }}</mat-label>
                <input class="ps-1" type="number" matInput formControlName="lightCount" min="0">
            </mat-form-field>
        </div>
    </div>
    <div class="row g-0 mt-2">
        <div class="col me-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'LIGHTING.POWER' | translate }}</mat-label>
                <input class="ps-1" type="number" matInput formControlName="lightPower" min="0">
            </mat-form-field>
        </div>
        <div class="col ms-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'LIGHTING.HOURS_DAILY' | translate }}</mat-label>
                <input class="ps-1" type="number" matInput formControlName="hoursPerDay" min="0">
            </mat-form-field>
        </div>
    </div>
</form>
