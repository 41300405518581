export enum LightType
{
    COMPACT = 'compact',
    LED = 'led',
    MERCURY_VAPOR = 'mercuryVapor',
    METAL_HALIDES = 'metalHalides',
    PROJECTOR = 'projector',
    SODIUM_VAPOR = 'sodiumSapor',
    FLUORESCENT = 'fluorescentes',
    TUBULAR = 'tubular',
    DISCHARGE = 'discharge',
    INCANDESCENT = 'incandescent',
    REGULAR = 'regular',
    HALOGEN = 'halogen'
}
