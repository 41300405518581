import {Component, Input} from '@angular/core';
import {find} from 'lodash';

interface NavigationElement
{
    step: number;
    translationKey: string;
    valid: boolean;
    active: boolean;
}

@Component({
    selector: 'app-top-nav',
    templateUrl: './top-nav.component.html',
    styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent
{
    @Input() public navigationElements: NavigationElement[];

    public getActiveNavigationElement(): NavigationElement
    {
        return find(this.navigationElements, (navElm: NavigationElement) => navElm.active);
    }

    public navigationClick(navigationElement: NavigationElement): void
    {
        find(this.navigationElements, (navElm: NavigationElement) => navElm.active).active = false;
        navigationElement.active = true;
    }
}
