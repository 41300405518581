<div class="d-flex justify-content-end">
    <div class="dropdown" [class.fixed]="fixed">
        <img [src]="'assets/images/flags/' + mainLanguage + '.svg'">
        <div class="custom-dropdown-menu">
            <div class="dropdown-item-1" *ngIf="selectedLanguageHide('pt')" (click)="switchLanguage('pt')">
                <img src="assets/images/flags/pt.svg" alt="pt_flag">
            </div>
            <div class="dropdown-item-2" *ngIf="selectedLanguageHide('en')" (click)="switchLanguage('en')">
                <img src="assets/images/flags/en.svg" alt="en-flag">
            </div>
        </div>
    </div>
</div>
