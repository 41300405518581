import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-lpg-gas',
    templateUrl: './lpg-gas.component.html',
    styleUrls: ['./lpg-gas.component.scss']
})
export class LpgGasComponent
{
    @Input() public lpgGasFormGroup: FormGroup;

    public onSubmit(): void
    { }
}
