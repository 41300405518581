import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CharEPreventDirective} from './char-e-prevent.directive';
import {CustomModalComponent} from './custom-modal/custom-modal.component';
import {SwitchLanguageComponent} from './switch-language/switch-language.component';
import {TimepickerComponent} from './timepicker/timepicker.component';

@NgModule({
    declarations: [SwitchLanguageComponent, CustomModalComponent, CharEPreventDirective, TimepickerComponent],
    imports:
    [
        TranslateModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatCheckboxModule,
        MatSelectModule
    ],
    exports:
    [
        CommonModule,
        TranslateModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        SwitchLanguageComponent,
        CustomModalComponent,
        CharEPreventDirective,
        TimepickerComponent
    ]
})
export class SharedModule
{}
