import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {LightType} from '@domain/light-type.enum';
import {EnumUtils} from '@shared/enum-utils';
import {isEqual, map} from 'lodash';

@Component({
    selector: 'app-lighting-source-details',
    templateUrl: './lighting-source-details.component.html',
    styleUrls: ['./lighting-source-details.component.scss']
})
export class LightingSourceDetailsComponent implements OnInit
{
    @Input() public type: LightType;
    @Input() public receivedformGroup: FormGroup;
    public translationTitle: string;

    private readonly lightTypesFluorescent =
        [
            LightType.COMPACT,
            LightType.TUBULAR
        ];

    private readonly lightTypesLed =
        [
            ...this.lightTypesFluorescent,
            LightType.PROJECTOR
        ];

    public lightTypesDischarge =
        [
            LightType.MERCURY_VAPOR,
            LightType.SODIUM_VAPOR,
            LightType.METAL_HALIDES
        ];

    public lightTypesIncandescent =
        [
            LightType.REGULAR,
            LightType.HALOGEN
        ];

    public ngOnInit(): void
    {
        this.translationTitle = `LIGHTING.TYPE.${EnumUtils.getKeyFromValue(LightType, this.type)}`;
    }

    private getKeysArray(array: LightType[]): string[]
    {
        return map(array, (lightType: LightType) => EnumUtils.getKeyFromValue(LightType, lightType));
    }

    public getTypes(): string[]
    {
        let result: string[];

        if (isEqual(this.type, LightType.LED))
        {
            result = this.getKeysArray(this.lightTypesLed);
        }
        else if (isEqual(this.type, LightType.FLUORESCENT))
        {
            result = this.getKeysArray(this.lightTypesFluorescent);
        }
        else if (isEqual(this.type, LightType.DISCHARGE))
        {
            result = this.getKeysArray(this.lightTypesDischarge);
        }
        else
        {
            result = this.getKeysArray(this.lightTypesIncandescent);
        }

        return result;
    }
}
