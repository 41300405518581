import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ResultGuard implements CanActivate
{

    public constructor(private readonly router: Router)
    { }

    public canActivate(snapshot: ActivatedRouteSnapshot): boolean | Promise<boolean>
    {
        const {state} = this.router.getCurrentNavigation().extras;

        return state !== undefined || localStorage.getItem('formArray') !== null ? true : this.router.navigateByUrl(snapshot.params.lang);
    }
}
