import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {BrowserTransferStateModule, TransferState} from '@angular/platform-browser';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {svgBrowserLoaderFactory} from '@shared/loaders/svg-browser-loader';
import {ModalService} from '@shared/modal.service';
import {SharedModule} from '@shared/shared.module';
import {AngularSvgIconModule, SvgLoader} from 'angular-svg-icon';
import {EnergyEfficiencyComponent} from './energy-efficiency/energy-efficiency.component';
import {EquipmentDetailsComponent} from './energy-efficiency/equipment-details/equipment-details.component';
import {IdentificationComponent} from './identification/identification.component';
import {EletricityComponent} from './info-to-attach/eletricity/eletricity.component';
import {PriceConsumptionComponent} from './info-to-attach/eletricity/price-consumption/price-consumption.component';
import {FileUploaderComponent} from './info-to-attach/file-uploader/file-uploader.component';
import {InfoToAttachComponent} from './info-to-attach/info-to-attach.component';
import {LpgGasComponent} from './info-to-attach/lpg-gas/lpg-gas.component';
import {NaturalGasComponent} from './info-to-attach/natural-gas/natural-gas.component';
import {OtherComponent} from './info-to-attach/other/other.component';
import {LightingSourceDetailsComponent} from './lighting/lighting-source-details/lighting-source-details.component';
import {LightingComponent} from './lighting/lighting.component';
import {RenewablesAndEnergyGenerationComponent} from './renewables-and-energy-generation/renewables-and-energy-generation.component';
import {SimulatorModuleRoutingModule} from './simulator-routing.module';
import {SimulatorComponent} from './simulator.component';
import {TopNavComponent} from './top-nav/top-nav.component';
import {BaseScheduleComponent} from './usage-profile/base-schedule/base-schedule.component';
import {UsageProfileComponent} from './usage-profile/usage-profile.component';

@NgModule({
    imports:
        [
            TransferHttpCacheModule,
            SharedModule,
            SimulatorModuleRoutingModule,
            AngularSvgIconModule.forRoot({
                loader: {
                    provide: SvgLoader,
                    useFactory: svgBrowserLoaderFactory,
                    deps: [HttpClient, TransferState]
                }
            }),
            BrowserTransferStateModule,
            MatFormFieldModule,
            MatInputModule,
            MatRadioModule,
            MatDatepickerModule,
            MatNativeDateModule,
            MatCheckboxModule,
            MatSelectModule
        ],
    declarations: [SimulatorComponent, TopNavComponent, IdentificationComponent, InfoToAttachComponent, EletricityComponent, NaturalGasComponent, LpgGasComponent, OtherComponent, RenewablesAndEnergyGenerationComponent, LightingComponent, LightingSourceDetailsComponent, EnergyEfficiencyComponent, EquipmentDetailsComponent, UsageProfileComponent, BaseScheduleComponent, PriceConsumptionComponent, FileUploaderComponent],
    providers:
        [
            {
                provide: MAT_RADIO_DEFAULT_OPTIONS,
                useValue: {color: '$'}
            },
            ModalService
        ]
})
export class SimulatorModule
{ }
