/* eslint-disable @typescript-eslint/no-unused-expressions */
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LightType} from '@domain/light-type.enum';
import {isEqual} from 'lodash';

@Component({
    selector: 'app-lighting',
    templateUrl: './lighting.component.html',
    styleUrls: ['./lighting.component.scss']
})
export class LightingComponent implements OnInit
{
    @Output() public navigateBack = new EventEmitter<FormGroup>();
    @Output() public navigateForward = new EventEmitter<FormGroup>();

    public nextButtonPressed = false;

    public lightingFormGroup = new FormGroup({
        existingLightNaturalLight: new FormControl(false),
        existingLightLed: new FormControl(false),
        existingLightFluorescent: new FormControl(false),
        existingLightIncandescent: new FormControl(false),
        existingLightDischarge: new FormControl(false),
        radioLightControl: new FormControl('', Validators.required),
        lightCtrlTimer: new FormControl(false),
        lightCtrlMotionSensor: new FormControl(false),
        lightCtrlCrepuscular: new FormControl(false),
        lightCtrlBrightness: new FormControl(false)
    });

    public constructor(private readonly fb: FormBuilder)
    { }

    public ngOnInit(): void
    {
        this.lightingFormGroup.get('existingLightLed').valueChanges.subscribe((value) =>
        {
            value ? this.lightingFormGroup.addControl('ledFormGroup', this.getBaseFormGroup()) : this.lightingFormGroup.removeControl('ledFormGroup');
        });

        this.lightingFormGroup.get('existingLightFluorescent').valueChanges.subscribe((value) =>
        {
            value ? this.lightingFormGroup.addControl('fluorescentFormGroup', this.getBaseFormGroup()) : this.lightingFormGroup.removeControl('fluorescentFormGroup');
        });

        this.lightingFormGroup.get('existingLightIncandescent').valueChanges.subscribe((value) =>
        {
            value ? this.lightingFormGroup.addControl('incandescentVaporFormGroup', this.getBaseFormGroup()) : this.lightingFormGroup.removeControl('incandescentVaporFormGroup');
        });

        this.lightingFormGroup.get('existingLightDischarge').valueChanges.subscribe((value) =>
        {
            value ? this.lightingFormGroup.addControl('dischargeFormGroup', this.getBaseFormGroup()) : this.lightingFormGroup.removeControl('dischargeFormGroup');
        });
    }

    public onSubmit(): void
    {
        this.nextButtonPressed = true;
        if (this.lightingFormGroup.valid && !this.areExistingLightCheckboxesInvalid() && !this.areLightControlCheckboxesInvalid())
        {
            window.scrollTo({top: 0});
            this.navigateForward.emit(this.lightingFormGroup);
        }
        else
        {
            this.lightingFormGroup.markAllAsTouched();
        }
    }

    public navigateToPreviousPage(): void
    {
        window.scrollTo({top: 0});
        this.navigateBack.emit(this.lightingFormGroup);
    }

    public areExistingLightCheckboxesInvalid(): boolean
    {
        return this.nextButtonPressed
            && !isEqual(this.lightingFormGroup.get('existingLightNaturalLight').value, true)
            && !isEqual(this.lightingFormGroup.get('existingLightLed').value, true)
            && !isEqual(this.lightingFormGroup.get('existingLightFluorescent').value, true)
            && !isEqual(this.lightingFormGroup.get('existingLightIncandescent').value, true)
            && !isEqual(this.lightingFormGroup.get('existingLightDischarge').value, true);
    }

    public areLightControlCheckboxesInvalid(): boolean
    {
        return isEqual(this.lightingFormGroup.get('radioLightControl').value, 'true') && this.nextButtonPressed
            && !isEqual(this.lightingFormGroup.get('lightCtrlTimer').value, true)
            && !isEqual(this.lightingFormGroup.get('lightCtrlMotionSensor').value, true)
            && !isEqual(this.lightingFormGroup.get('lightCtrlCrepuscular').value, true)
            && !isEqual(this.lightingFormGroup.get('lightCtrlBrightness').value, true);
    }

    public optionsSelected(): {type: LightType; formGroupName: string;}[]
    {
        const lightTypes: {type: LightType; formGroupName: string;}[] = [];

        if (this.lightingFormGroup.get('existingLightLed').value)
        {
            lightTypes.push({type: LightType.LED, formGroupName: 'ledFormGroup'});
        }
        if (this.lightingFormGroup.get('existingLightFluorescent').value)
        {
            lightTypes.push({type: LightType.FLUORESCENT, formGroupName: 'fluorescentFormGroup'});
        }
        if (this.lightingFormGroup.get('existingLightIncandescent').value)
        {
            lightTypes.push({type: LightType.INCANDESCENT, formGroupName: 'incandescentVaporFormGroup'});
        }
        if (this.lightingFormGroup.get('existingLightDischarge').value)
        {
            lightTypes.push({type: LightType.DISCHARGE, formGroupName: 'dischargeFormGroup'});
        }

        return lightTypes;
    }

    public trackyByFormGroupName(index: number, lightType: {type: LightType; formGroupName: string;}): string
    {
        return lightType.formGroupName;
    }

    public areRadiosInvalid(): boolean
    {
        return this.nextButtonPressed && this.lightingFormGroup.get('radioLightControl').invalid;
    }

    public canShowLightControlOptions(): boolean
    {
        return this.lightingFormGroup.get('radioLightControl').value;
    }

    private getBaseFormGroup(): FormGroup
    {
        return this.fb.group({
            lightType: new FormControl('', Validators.required),
            lightCount: new FormControl('', Validators.required),
            lightPower: new FormControl('', Validators.required),
            hoursPerDay: new FormControl('', [Validators.required, Validators.max(24)])
        });
    }
}
