<form [formGroup]="identificationForm" (ngSubmit)="onSubmit()">
    <div class="row g-0">
        <div class="col me-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'IDENTIFICATION.COMPANY_NAME' | translate }}</mat-label>
                <input class="ps-1" type="text" matInput formControlName="companyName">
                <img class="error-icon" *ngIf="isInvalid('companyName')" src="assets/images/icons/erro.svg" matSuffix alt="error icon" />
            </mat-form-field>
        </div>
        <div class="col ms-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'IDENTIFICATION.PERSON_NAME' | translate }}</mat-label>
                <input class="ps-1" type="text" matInput formControlName="companyPerson">
                <img class="error-icon" *ngIf="isInvalid('companyPerson')" src="assets/images/icons/erro.svg" matSuffix alt="error icon" />
            </mat-form-field>
        </div>
    </div>
    <div class="row g-0">
        <div class="col me-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'IDENTIFICATION.EMAIL' | translate }}</mat-label>
                <input class="ps-1" type="email" matInput formControlName="email">
                <img class="error-icon" *ngIf="isInvalid('email')" src="assets/images/icons/erro.svg" matSuffix alt="error icon" />
            </mat-form-field>
        </div>
        <div class="col ms-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'IDENTIFICATION.CONTACT' | translate }}</mat-label>
                <input class="ps-1" type="tel" matInput formControlName="contact">
            </mat-form-field>
        </div>
    </div>
    <div class="row g-0">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'IDENTIFICATION.ADDRESS' | translate }}</mat-label>
            <input class="ps-1" type="text" matInput formControlName="address">
        </mat-form-field>
    </div>
    <div class="row g-0">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'IDENTIFICATION.BUILD_DATE' | translate }}</mat-label>
            <input matInput (click)="picker.open()" [matDatepicker]="picker" formControlName="buildDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="row g-0">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'IDENTIFICATION.COMPANY_DESCRIPTION' | translate }}</mat-label>
            <input class="ps-1" type="text" matInput formControlName="description">
        </mat-form-field>
    </div>
    <div class="row g-0 companys-activity">
        <label id="radios-title" class="montserrat-bold fs-16 p-blue" [class.invalid]="areRadiosInvalid()">{{ 'IDENTIFICATION.COMPANY_ACTIVITY' | translate }}*</label>
        <mat-radio-group aria-labelledby="radios-title" class="radio-group d-flex flex-column align-items-start" formControlName="companysActivity" [class.invalid]="areRadiosInvalid()">
            <mat-radio-button class="m-1 d-flex align-items-start" *ngFor="let activity of activities" [value]="activity">
                <span class="montserrat-regular ts-14 s-black text-wrap">{{ getActivitiesTranslation(activity) | translate }}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="row g-0 d-flex justify-content-end">
        <button type="submit" class="btn primary-button montserrat-regular">{{ 'NEXT' | translate }}</button>
    </div>
</form>
