import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FileUploaderService} from '@shared/file-uploader.service';
import {ConsumeType} from './consume-type';

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss']
})

export class FileUploaderComponent
{
    @Input() public group: FormGroup;
    @Input() public type: ConsumeType;
    public fileNameHtml = '';
    private fileName: string;

    public constructor(private readonly uploaderService: FileUploaderService)
    { }

    public removeFile(): void
    {
        this.uploaderService.removeFile(this.fileName);
        this.fileNameHtml = '';
    }

    public onFileSelected(eventFile): void
    {
        const file: File = eventFile.target.files[0];

        if (file)
        {
            this.fileNameHtml = file.name;
            this.fileName = `${this.type}_pdf`;

            this.uploaderService.addFile(this.fileName, file);
        }
    }
}
