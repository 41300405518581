<footer class="footer fixed-bottom">
    <div class="row g-0 logos-row d-flex align-items-center justify-content-around">
        <div class="col col-md-auto d-flex align-items-center">
            <svg-icon src="assets/images/footer/logo_aecoa.svg" [svgStyle]="{'height.px':47, 'width.px':145, 'margin-right.px':16}"></svg-icon>
            <svg-icon src="assets/images/footer/logo_aea.svg" [svgStyle]="{'height.px':90, 'width.px':190}"></svg-icon>
        </div>
        <div class="col col-md-auto d-flex align-items-center">
            <svg-icon src="assets/images/footer/portugal2020.svg" [svgStyle]="{'height.px':64, 'width.px': 416}"></svg-icon>
        </div>
    </div>
    <div class="row g-0 bottom-row align-items-center">
        <div class="col-md-auto">
            <button type="button" class="btn" aria-label="View cookie settings" data-cc="c-settings">
                <img src="assets/images/footer/cookies-trigger.svg" class="cookie-button" />
            </button>
        </div>
        <div class="col">
            <span class="montserrat-regular">Qualify.teca/AEA</span>
        </div>
        <div class="col d-flex justify-content-end">
            <span class="montserrat-regular link" (click)="navigateToLoad()">Designed by LOAD</span>
        </div>
    </div>
</footer>
