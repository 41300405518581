import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-natural-gas',
    templateUrl: './natural-gas.component.html',
    styleUrls: ['./natural-gas.component.scss']
})
export class NaturalGasComponent
{
    @Input() public naturalGasFormGroup: FormGroup;
    public naturalGasFormGroupIsSubmitted = false;

    public supplyPressureLevelOptions =
        [
            'HIGH_PREASSURE',
            'MEDIUM_PREASSURE',
            'LOW_PREASSURE_BETWEEN',
            'LOW_PREASSURE',
            'LOW_PREASSURE_DOMESTIC'
        ];
    
    public getSupplyTransalation(supply: string): string
    {
        return `INFO_TO_ATTACH.NATURAL_GAS_PART.SUPPLY_OPTIONS.${supply}`;
    }

    public onSubmit(): void
    { }
}
