import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {OtherEnergySource} from '@domain/other-energy-source.enum';
import {isEqual} from 'lodash';

@Component({
    selector: 'app-other',
    templateUrl: './other.component.html',
    styleUrls: ['./other.component.scss']
})
export class OtherComponent implements OnInit
{
    @Input() public otherFormGroup: FormGroup;

    public sourceNames =
        [
            OtherEnergySource.PELLETS,
            OtherEnergySource.BIOMASSA_PRIMARIA,
            OtherEnergySource.MADEIRA,
            OtherEnergySource.GASOLEO
        ];

    public units =
        [
            {translation: 'INFO_TO_ATTACH.OTHER_PART.METERS', value: 'meters'},
            {translation: 'INFO_TO_ATTACH.OTHER_PART.LITERS', value: 'liters'}
        ];

    public constructor(private readonly fb: FormBuilder)
    { }

    public ngOnInit(): void
    {
        this.addNewOther();
    }

    public getSourceNameTranslation(sourceName: OtherEnergySource): string
    {
        return `INFO_TO_ATTACH.OTHER_PART.SOURCE.${sourceName}`;
    }

    public get others(): FormArray
    {
        return this.otherFormGroup.get('otherArray') as FormArray;
    }

    public othersHasMaxElements(): boolean
    {
        return !(this.others.controls.length < 4);
    }

    public addNewOther(): void
    {
        if (!this.othersHasMaxElements())
        {
            this.others.push(
                this.fb.group({
                    sourceName: new FormControl('', [Validators.required]),
                    otherConsumption: new FormControl('', [Validators.required, Validators.min(0)]),
                    otherPrice: new FormControl('')
                })
            );
        }
    }

    public removeOther(index: number): void
    {
        if (this.canRemoveOther())
        {
            this.others.removeAt(index);
        }
    }
    
    public canRemoveOther(): boolean
    {
        return !isEqual(this.others.controls.length, 1);
    }
}
