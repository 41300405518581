<form [formGroup]="usageProfileFormGroup" class="container" (ngSubmit)="onSubmit()">
    <div class="row g-0">
        <span class="montserrat-semibold fs-14 p-blue" [class.invalid]="areRadiosInvalid('radioWeekOperationPeriod')">{{ 'USAGE_PROFILE.WEEK_OPERATION_PERIOD' | translate }}*</span>
        <mat-radio-group aria-labelledby="radios-title" class="radio-group d-flex flex-column align-items-start" formControlName="radioWeekOperationPeriod" [class.invalid]="areRadiosInvalid('radioWeekOperationPeriod')">
            <mat-radio-button class="m-1 d-flex align-items-start" *ngFor="let period of weekWorkPeriods" [value]="period.value">
                <span class="montserrat-medium ts-14 s-black text-wrap">{{ period.translation | translate }}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div *ngIf="isSelected('week', 'single')" class="row-schedule">
        <app-base-schedule title="USAGE_PROFILE.SINGLE_SCHEDULE" [receivedFormGroup]="usageProfileFormGroup.get('singleScheduleFormGroup')"></app-base-schedule>
    </div>
    <div *ngIf="isSelected('week', 'exception')" class="row-schedule" formGroupName="exceptionScheduleFormGroup">
        <div class="end-shifts">
            <app-base-schedule title="USAGE_PROFILE.BASE_SCHEDULE" [receivedFormGroup]="usageProfileFormGroup.get('exceptionScheduleFormGroup')"></app-base-schedule>
        </div>
        <div class="row g-0 mt-3">
            <ng-container formArrayName="exceptions">
                <ng-container *ngFor="let exception of usageProfileFormGroup.get('exceptionScheduleFormGroup.exceptions').controls; let exceptionIndex = index">
                    <div class="row g-0 mb-2" [formGroup]="exception">
                        <app-base-schedule title="titulo" [receivedFormGroup]="exception" [hasDayOfTheWeek]="true" [index]="exceptionIndex" (remove)="removeException($event)"></app-base-schedule>
                    </div>
                </ng-container>
                <div class="row g-0 d-flex justify-content-end">
                    <div class="col col-md-auto" (click)="addException()">
                        <span class="montserrat-medium fs-14 s-black text-decoration-underline me-1 text-link">{{ 'USAGE_PROFILE.ADD_STOP' | translate }}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="isSelected('week', 'daily')" class="row-schedule" formGroupName="dailyScheduleFormGroup">
        <app-base-schedule title="WEEKDAYS.MONDAY" [receivedFormGroup]="usageProfileFormGroup.get('dailyScheduleFormGroup.monday')"></app-base-schedule>
        <div class="mb-3 end-shifts"></div>
        <app-base-schedule title="WEEKDAYS.TUESDAY" [receivedFormGroup]="usageProfileFormGroup.get('dailyScheduleFormGroup.tuesday')"></app-base-schedule>
        <div class="mb-3 end-shifts"></div>
        <app-base-schedule title="WEEKDAYS.WEDNESDAY" [receivedFormGroup]="usageProfileFormGroup.get('dailyScheduleFormGroup.wednesday')"></app-base-schedule>
        <div class="mb-3 end-shifts"></div>
        <app-base-schedule title="WEEKDAYS.THURSDAY" [receivedFormGroup]="usageProfileFormGroup.get('dailyScheduleFormGroup.thursday')"></app-base-schedule>
        <div class="mb-3 end-shifts"></div>
        <app-base-schedule title="WEEKDAYS.FRIDAY" [receivedFormGroup]="usageProfileFormGroup.get('dailyScheduleFormGroup.friday')"></app-base-schedule>
    </div>
    <div class="row g-0 new-section">
        <div class="row g-0">
            <span class="montserrat-semibold fs-14 p-blue" [class.invalid]="areRadiosInvalid('radioWeekendOperationPeriod')">{{ 'USAGE_PROFILE.WEEKEND_OPERATION_PERIOD' | translate }}*</span>
            <mat-radio-group aria-labelledby="radios-title" class="radio-group d-flex flex-column align-items-start" formControlName="radioWeekendOperationPeriod" [class.invalid]="areRadiosInvalid('radioWeekendOperationPeriod')">
                <mat-radio-button class="m-1 d-flex align-items-start" *ngFor="let period of weekendWorkPeriods" [value]="period.value">
                    <span class="montserrat-medium ts-14 s-black text-wrap">{{ period.translation | translate }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="row g-0">
            <div *ngIf="isSelected('weekend', 'single')" class="row-schedule">
                <app-base-schedule title="USAGE_PROFILE.SINGLE_SCHEDULE" [receivedFormGroup]="usageProfileFormGroup.get('weekendSingleScheduleFormGroup')"></app-base-schedule>
            </div>
            <div *ngIf="isSelected('weekend', 'daily')" class="row-schedule" formGroupName="weekendDailyScheduleFormGroup">
                <div formGroupName="saturday">
                    <app-base-schedule title="WEEKDAYS.SATURDAY" [receivedFormGroup]="usageProfileFormGroup.get('weekendDailyScheduleFormGroup.saturday')" weekend="saturday"></app-base-schedule>
                </div>
                <div formGroupName="sunday" class="mt-2">
                    <app-base-schedule title="WEEKDAYS.SUNDAY" [receivedFormGroup]="usageProfileFormGroup.get('weekendDailyScheduleFormGroup.sunday')" weekend="sunday"></app-base-schedule>
                </div>
            </div>
        </div>
    </div>
    <div class="row g-0 new-section">
        <div class="row g-0">
            <span class="montserrat-semibold fs-14 p-blue" [class.invalid]="datesInvalid()">{{ 'USAGE_PROFILE.DURATION_EXTENDED_STOPS' | translate }}*</span>
        </div>
        <ng-container formArrayName="extendedStops">
            <ng-container *ngFor="let extendedStopForm of extendedStops.controls; let i = index">
                <div class="row g-0 mt-3" [formGroup]=" extendedStopForm">
                    <div class="col me-2">
                        <mat-form-field appearance="standard">
                            <mat-label>{{ 'USAGE_PROFILE.START_DATE' | translate }}</mat-label>
                            <input matInput (click)="pickerStart.open()" [matDatepicker]="pickerStart" [max]="getEndDateValue(extendedStopForm)" formControlName="start">
                            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                            <mat-datepicker #pickerStart></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col ms-2">
                        <mat-form-field appearance="standard">
                            <mat-label>{{ 'USAGE_PROFILE.END_DATE' | translate }}</mat-label>
                            <input matInput (click)="pickerEnd.open()" [matDatepicker]="pickerEnd" [min]="getStartDateValue(extendedStopForm)" formControlName="end">
                            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                            <mat-datepicker #pickerEnd></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col col-md-auto d-flex align-items-center remove-icon" [class.disabled]="!canRemoveStop()" (click)="removeExtendedStop(i)">
                        <svg-icon src="assets/images/icons/trash.svg"></svg-icon>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <div *ngIf="canAddStop()" class="row g-0 d-flex justify-content-end">
            <div class="col col-md-auto" (click)="addExtendedStop()">
                <span class="montserrat-medium fs-14 s-black text-decoration-underline me-1 text-link">{{ 'USAGE_PROFILE.ADD_STOP' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="row g-0 new-section">
        <div class="row g-0">
            <span class="montserrat-semibold fs-14 p-blue">{{ 'USAGE_PROFILE.NUMBER_OF_EMPLOYEES' | translate }}</span>
        </div>
        <div class="row g-0 mt-3">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'USAGE_PROFILE.ENTER_NUMBER' | translate }}</mat-label>
                <input class="ps-1" type="number" matInput formControlName="employeeNumber" min="0">
            </mat-form-field>
        </div>
    </div>
    <div class="row g-0 new-section">
        <div class="row g-0">
            <span class="montserrat-semibold fs-14 p-blue">{{ 'USAGE_PROFILE.NUMBER_OF_UNITS_PRODUCES_YEARLY' | translate }}</span>
        </div>
        <div class="row g-0 mt-3">
            <div class="col me-2">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'USAGE_PROFILE.ITEMS_QUANTITY' | translate }}</mat-label>
                    <input class="ps-1" type="number" matInput formControlName="itemsProduced" min="0">
                </mat-form-field>
            </div>
            <div class="col col-4 ms-2">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'USAGE_PROFILE.CHOOSE_THE_UNIT' | translate }}</mat-label>
                    <mat-select formControlName="itemsProducedUnits">
                        <mat-option *ngFor="let unit of units" [value]="unit" class="montserrat-medium fs-14 s-black">
                            {{ getUnitTranslation(unit) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="row g-0 d-flex justify-content-between">
        <div class="col col-md-auto">
            <button type="button" (click)="navigateToPreviousPage()" class="btn secundary-button montserrat-regular">{{ 'PREVIOUS' | translate }}</button>
        </div>
        <div class="col col-md-auto">
            <button type="submit" class="btn primary-button montserrat-regular">{{ 'NEXT' | translate }}</button>
        </div>
    </div>
</form>
