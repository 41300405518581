<form [formGroup]="renewablesFormGroup" class="container" (ngSubmit)="onSubmit()">
    <div class="row g-0">
        <div class="row g-0">
            <span class="montserrat-semibold fs-14 p-blue" [class.invalid]="areRadiosInvalid()">{{ 'INFO_TO_ATTACH.PREVIOUS_AUDITS' | translate }}*</span>
        </div>
        <div class="row g-0 mt-3">
            <mat-radio-group class="d-flex flex-row justify-content-start" formControlName="radioRenewable" [class.invalid]="areRadiosInvalid()">
                <mat-radio-button class="m-1 col col-2" [value]=true>
                    <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'YES' | translate }}</span>
                </mat-radio-button>
                <mat-radio-button class="m-1 col col-2" [value]=false>
                    <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'NO' | translate }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="canShowRenewableOptions()" class="row g-0 mt-3 ms-2">
            <div class="row g-0">
                <div class="col col-6">
                    <p>
                        <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="photovoltaic" [class.invalid]="areCheckboxesInvalid()">
                            {{ 'RENEWABLES.PHOTOVOLTAIC' | translate }}
                        </mat-checkbox>
                    </p>
                </div>
                <div class="col col-6">
                    <p>
                        <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="cogeneration" [class.invalid]="areCheckboxesInvalid()">
                            {{ 'RENEWABLES.COGENERATION' | translate }}
                        </mat-checkbox>
                    </p>
                </div>
                <div class="col col-6">
                    <p>
                        <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="thermal" [class.invalid]="areCheckboxesInvalid()">
                            {{ 'RENEWABLES.THERMAL' | translate }}
                        </mat-checkbox>
                    </p>
                </div>
                <div class="col col-6">
                    <p>
                        <mat-checkbox class="montserrat-medium fs-14 s-black" formControlName="windEnergy" [class.invalid]="areCheckboxesInvalid()">
                            {{ 'RENEWABLES.WIND_ENERGY' | translate }}
                        </mat-checkbox>
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="canShowRenewableOptions()" class="row g-0 mt-3 ms-2">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'RENEWABLES.ENTER_CAPACITY' | translate }}</mat-label>
                <input class="ps-1" type="number" matInput formControlName="renewableCapacity" min="0">
            </mat-form-field>
        </div>
    </div>
    <div class="row g-0 d-flex justify-content-between">
        <div class="col col-md-auto">
            <button type="button" (click)="navigateToPreviousPage()" class="btn secundary-button montserrat-regular">{{ 'PREVIOUS' | translate }}</button>
        </div>
        <div class="col col-md-auto">
            <button type="submit" class="btn primary-button montserrat-regular">{{ 'SIMULATE' | translate }}</button>
        </div>
    </div>
</form>
