import {Location} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Language} from '@domain/lang.enum';
import {TranslateService} from '@ngx-translate/core';
import {CookieConsentService} from '@shared/cookie-consent.service';

@Component({
    selector: 'app-switch-language',
    templateUrl: './switch-language.component.html',
    styleUrls: ['./switch-language.component.scss']
})
export class SwitchLanguageComponent implements OnInit
{
    @Input() public fixed = true;
    public mainLanguage: string;
    
    public constructor(
        private readonly translate: TranslateService,
        private readonly location: Location,
        private readonly cookiesService: CookieConsentService,
        private readonly router: Router
    )
    {}

    public ngOnInit(): void
    {
        this.mainLanguage = this.translate.currentLang;
    }
    
    public switchLanguage(language: string): void
    {
        const currentUrl = this.router.url;
        const currentArea = currentUrl.split('/')[2];
        this.translate.use(language);
        this.mainLanguage = language;
        this.location.replaceState(`${language}/${currentArea}`);
        this.cookiesService.updateLanguage(language as Language);
    }
    
    public selectedLanguageHide(language: string): boolean
    {
        return language !== this.mainLanguage;
    }
}
