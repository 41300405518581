import {formatDate} from '@angular/common';
import {Component, Injectable} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter} from '@angular/material/core';
import {Router} from '@angular/router';
import {AnalyticsEvent} from '@domain/analytics-event.enum';
import {TranslateService} from '@ngx-translate/core';
import {FirebaseService} from '@shared/firebase.service';
import {ModalService} from '@shared/modal.service';
import {find, isEqual, noop} from 'lodash';

interface NavigationElement
{
    step: number;
    translationKey: string;
    valid: boolean;
    active: boolean;
}

export const PICK_FORMATS = {
    parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
    display: {
        dateInput: 'input',
        monthYearLabel: {year: 'numeric', month: 'short'},
        dateA11yLabel: {year: 'numeric', month: 'short', day: 'numeric'},
        monthYearA11yLabel: {year: 'numeric', month: 'short'}
    }
};

@Injectable()
class PickDateAdapter extends NativeDateAdapter
{
    public format(date: Date, displayFormat: any): string
    {
        if (displayFormat === 'input')
        {
            return formatDate(date, 'dd-MM-yyyy', this.locale);
        }
        else
        {
            return date.toDateString();
        }
    }
}

@Component({
    selector: 'app-simulator',
    templateUrl: './simulator.component.html',
    styleUrls: ['./simulator.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: PickDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
    ]
})
export class SimulatorComponent
{
    public navigationElements: NavigationElement[] =
        [
            {
                step: 1,
                translationKey: 'NAVIGATION.IDENTIFICATION',
                valid: null,
                active: true
            },
            {
                step: 2,
                translationKey: 'NAVIGATION.INFORMATION_TO_BE_ATTACHED',
                valid: null,
                active: false
            },
            {
                step: 3,
                translationKey: 'NAVIGATION.USAGE_PROFILE',
                valid: null,
                active: false
            },
            {
                step: 4,
                translationKey: 'NAVIGATION.ENERGY_EFFICENCY',
                valid: null,
                active: false
            },
            {
                step: 5,
                translationKey: 'NAVIGATION.LIGHTING',
                valid: null,
                active: false
            },
            {
                step: 6,
                translationKey: 'NAVIGATION.RENEWABLES_AND_ENERGY_GENERATION',
                valid: null,
                active: false
            }
        ];

    private readonly formArrayGroup = this.fb.array([]);

    public constructor(
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly fb: FormBuilder,
        private readonly modalService: ModalService,
        private readonly firebaseService: FirebaseService
    )
    { }

    public isStepActive(step: number): boolean
    {
        return this.navigationElements[step - 1].active;
    }

    public getActiveStep(): NavigationElement
    {
        return find(this.navigationElements, (navElm: NavigationElement) => navElm.active);
    }

    public getActiveStepImagePath(): string
    {
        return `assets/images/side-images/${this.getActiveStep().step}_full.jpg`;
    }

    public getSideImageAlt(): string
    {
        let altText = '';

        switch (this.getActiveStep().step)
        {
            case 1:
                altText = 'man at work';
                break;
            case 2:
                altText = 'gray corridor';
                break;
            case 3:
                altText = 'white warehouse';
                break;
            case 4:
                altText = 'gray and blue pipes';
                break;
            case 5:
                altText = 'warehouse corridor';
                break;
            case 6:
                altText = 'solar panels';
                break;
        }

        return altText;
    }

    public navigate(formGroup: FormGroup, activeStep: number, direction: 'f' | 'b'): void
    {
        this.formArrayGroup.push(formGroup);

        if (isEqual(activeStep, 6) && isEqual(direction, 'f'))
        {
            this.modalService.openModal().then((simulate: boolean) =>
            {
                this.firebaseService.logEvent(AnalyticsEvent.SIMULATE_CLICK);
                const lang = this.translate.currentLang;
                this.router.navigateByUrl(`${lang}/result`, {state: {formArray: this.formArrayGroup.value}});
            }, () => noop);
        }
        else
        {
            this.getActiveStep().active = false;

            const futureStep = isEqual(direction, 'b') ? activeStep - 1 : activeStep + 1;

            this.firebaseService.logEvent(AnalyticsEvent.NAVIGATION, {
                navigationDetail: `Step ${activeStep} to step ${futureStep}`
            });

            find(this.navigationElements, (navElm: NavigationElement) => isEqual(navElm.step, futureStep)).active = true;
        }
    }
}
