import {Component, EventEmitter, Output} from '@angular/core';
import {Router} from '@angular/router';
import {AnalyticsEvent} from '@domain/analytics-event.enum';
import {TranslateService} from '@ngx-translate/core';
import {FirebaseService} from '@shared/firebase.service';

@Component({
    selector: 'app-homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent
{
    @Output() public changeLang = new EventEmitter<string>();

    public constructor(
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly firebaseService: FirebaseService
    )
    { }

    public navigateToSimulator(): void
    {
        this.firebaseService.logEvent(AnalyticsEvent.START_CLICK);
        const lang = this.translate.currentLang;
        this.router.navigateByUrl(`${lang}/simulator`);
    }
}
