import {Directive, HostListener} from '@angular/core';

@Directive({
    selector: 'input[type=number]'
})
export class CharEPreventDirective
{
    @HostListener('keydown', ['$event']) public disableKeys(event: any): boolean
    {
        return event.keyCode !== 69;
    }
}
