import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DaysWeek} from '@domain/days-week.enum';
import {TranslateService} from '@ngx-translate/core';
import {EnumUtils} from '@shared/enum-utils';
import {isEqual} from 'lodash';

@Component({
    selector: 'app-base-schedule',
    templateUrl: './base-schedule.component.html',
    styleUrls: ['./base-schedule.component.scss']
})
export class BaseScheduleComponent implements OnInit
{
    @Input() public title: string;
    @Input() public receivedFormGroup: FormGroup;
    @Input() public hasDayOfTheWeek = false;
    @Input() public index = 0;
    @Input() public weekend: 'saturday' | 'sunday';
    @Output() public remove = new EventEmitter<number>();
    public weekShiftDefault: number | string = 1;

    public shiftsDropdownValues: (string | number)[] =
        [
            1,
            2,
            3
        ];

    public weekDays: string[] =
        [
            EnumUtils.getKeyFromValue(DaysWeek, DaysWeek.MONDAY),
            EnumUtils.getKeyFromValue(DaysWeek, DaysWeek.TUESDAY),
            EnumUtils.getKeyFromValue(DaysWeek, DaysWeek.WEDNESDAY),
            EnumUtils.getKeyFromValue(DaysWeek, DaysWeek.THURSDAY),
            EnumUtils.getKeyFromValue(DaysWeek, DaysWeek.FRIDAY)
        ];

    public constructor(private readonly formBuilder: FormBuilder, private readonly translate: TranslateService)
    { }

    public ngOnInit(): void
    {
        if (isEqual(this.weekend, 'sunday'))
        {
            this.shiftsDropdownValues = ['NOT_APPLICABLE', ...this.shiftsDropdownValues];
            this.weekShiftDefault = 'NOT_APPLICABLE';
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (isEqual(this.weekend, 'saturday'))
        {
            this.shiftsDropdownValues = ['NOT_APPLICABLE', ...this.shiftsDropdownValues];
        }

        this.receivedFormGroup.get('numberOfShifts').valueChanges.subscribe((numShifts) =>
        {
            const newShifts = numShifts - this.shifts.length;

            if (newShifts > 0)
            {
                for (let shiftPosition = 0; shiftPosition < newShifts; shiftPosition++)
                {
                    const group = this.createShift();
                    this.shifts.push(group);
                }
            }
            else if (newShifts < 0)
            {
                for (let shiftPosition = 0; shiftPosition < -newShifts; shiftPosition++)
                {
                    this.shifts.removeAt(this.shifts.length - 1);
                }
            }

            if (isEqual(numShifts, 'NOT_APPLICABLE'))
            {
                for (let shiftPosition = this.shifts.length - 1; shiftPosition >= 0; shiftPosition--)
                {
                    this.shifts.removeAt(shiftPosition);
                }
            }
        });
    }

    public get shifts(): FormArray
    {
        return this.receivedFormGroup.get('shifts') as FormArray;
    }

    private createShift(): FormGroup
    {
        return this.formBuilder.group({
            startTime: new FormGroup({
                hour: new FormControl('', Validators.required),
                minute: new FormControl('', Validators.required)
            }),
            endTime: new FormGroup({
                hour: new FormControl('', Validators.required),
                minute: new FormControl('', Validators.required)
            })
        });
    }

    public getWeekDayTranslation(day: DaysWeek): string
    {
        return `WEEKDAYS.${day}`;
    }

    public removeException(index: number): void
    {
        this.remove.emit(index);
    }
}
