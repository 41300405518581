import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class InterceptedHttp implements HttpInterceptor
{
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any>
    {
        const token = 'TOKEN TEST';
        const headers: {accept: string; authorization: string;} =
        {
            accept: 'security/json, application/json',
            authorization: `JWT ${token}`
        };

        request = request.clone({
            url: request.url,
            setHeaders: headers
        });

        return next.handle(request);
    }
}
