<form [formGroup]="eficiencyFormGroup" class="container" (ngSubmit)="onSubmit()">
    <div class="row g-0">
        <div class="row g-0">
            <span class="montserrat-semibold fs-14 p-blue" [class.invalid]="areRadiosInvalid('radioEficiencyPolicy')">{{ 'ENERGY_EFICIENCY.ARE_THERE_EFICIENCY_POLICIES' | translate }}*</span>
        </div>
        <div class="row g-0 mt-3">
            <mat-radio-group class="example-radio-group d-flex flex-row justify-content-start" formControlName="radioEficiencyPolicy" [class.invalid]="areRadiosInvalid('radioEficiencyPolicy')">
                <mat-radio-button class="m-1 col col-2" [value]=true>
                    <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'YES' | translate }}</span>
                </mat-radio-button>
                <mat-radio-button class="m-1 col col-2" [value]=false>
                    <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'NO' | translate }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="canShowAddicionalField('radioEficiencyPolicy')" class="row g-0 mt-3">
            <mat-form-field appearance="standard">
                <mat-label>{{ 'ENERGY_EFICIENCY.DESCRIPTION' | translate }}</mat-label>
                <textarea matInput formControlName="eficiencyPolicyDescription"></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row g-0 implemented-policies-row">
        <div class="row g-0">
            <span class="montserrat-semibold fs-14 p-blue" [class.invalid]="areRadiosInvalid('radioImplementedMeasures')">{{ 'ENERGY_EFICIENCY.ARE_THERE_IMPLEMENTED_MEASURES' | translate }}*</span>
        </div>
        <div class="row g-0 mt-3">
            <mat-radio-group class="example-radio-group d-flex flex-row justify-content-start" formControlName="radioImplementedMeasures" [class.invalid]="areRadiosInvalid('radioImplementedMeasures')">
                <mat-radio-button class="m-1 col col-2" [value]=true>
                    <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'YES' | translate }}</span>
                </mat-radio-button>
                <mat-radio-button class="m-1 col col-2" [value]=false>
                    <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'NO' | translate }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div *ngIf="canShowAddicionalField('radioImplementedMeasures')" class="row g-0 mt-3 ms-2">
            <div class="row g-0">
                <mat-checkbox class="montserrat-regular fs-14 p-blue" formControlName="replacementToLed" [class.invalid]="areEficiencyMeasuresCheckboxesInvalid()">{{ 'ENERGY_EFICIENCY.REPLACEMENT_TO_LED' | translate }}</mat-checkbox>
            </div>
            <div class="row g-0 mt-4">
                <mat-checkbox class="montserrat-regular fs-14 p-blue" formControlName="installationCapacitorBanks" [class.invalid]="areEficiencyMeasuresCheckboxesInvalid()">{{ 'ENERGY_EFICIENCY.INSTALLATION_CAPACITOR_BANKS' | translate }}</mat-checkbox>
            </div>
            <div class="row g-0 mt-4">
                <mat-checkbox class="montserrat-regular fs-14 p-blue" formControlName="electronicSpeedVariators" [class.invalid]="areEficiencyMeasuresCheckboxesInvalid()">{{ 'ENERGY_EFICIENCY.ELECTRONIC_SPEED_VARIATORS' | translate }}</mat-checkbox>
            </div>
            <div class="row g-0 mt-4">
                <mat-checkbox class="montserrat-regular fs-14 p-blue" formControlName="highEfficiencyMotors" [class.invalid]="areEficiencyMeasuresCheckboxesInvalid()">{{ 'ENERGY_EFICIENCY.HIGH_EFFICIENCY_MOTORS' | translate }}</mat-checkbox>
            </div>
            <div class="row g-0 mt-4">
                <mat-checkbox class="montserrat-regular fs-14 p-blue" formControlName="compressedAirOptimization" [class.invalid]="areEficiencyMeasuresCheckboxesInvalid()">{{ 'ENERGY_EFICIENCY.COMPRESSED_AIR_OPTIMIZATION' | translate }}</mat-checkbox>
            </div>
            <div class="row g-0 mt-4">
                <mat-checkbox class="montserrat-regular fs-14 p-blue" formControlName="thermalSystemsOptimization" [class.invalid]="areEficiencyMeasuresCheckboxesInvalid()">{{ 'ENERGY_EFICIENCY.THERMAL_SYSTEMS_OPTIMIZATION' | translate }}</mat-checkbox>
            </div>
        </div>
    </div>
    <div class="row g-0 implemented-policies-row">
        <div class="row g-0 mt-3">
            <div class="row g-0">
                <span class="montserrat-semibold fs-14 p-blue" [class.invalid]="areRadiosInvalid('radioSomeoneValidatingInvoices')">{{ 'ENERGY_EFICIENCY.IS_THERE_MONITORING' | translate }}*</span>
            </div>
            <div class="row g-0 mt-3">
                <mat-radio-group class="example-radio-group d-flex flex-row justify-content-start" formControlName="radioMonitoring" [class.invalid]="areRadiosInvalid('radioMonitoring')">
                    <mat-radio-button class="m-1 col col-2" [value]=true>
                        <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'YES' | translate }}</span>
                    </mat-radio-button>
                    <mat-radio-button class="m-1 col col-2" [value]=false>
                        <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'NO' | translate }}</span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="row g-0 implemented-policies-row">
            <div class="row g-0">
                <span class="montserrat-semibold fs-14 p-blue" [class.invalid]="areRadiosInvalid('radioSomeoneValidatingInvoices')">{{ 'ENERGY_EFICIENCY.IS_THERE_SOMEONE_VALIDATING_INVOICES' | translate }}</span>
            </div>
            <div class="row g-0 mt-3">
                <mat-radio-group class="example-radio-group d-flex flex-row justify-content-start" formControlName="radioSomeoneValidatingInvoices" [class.invalid]="areRadiosInvalid('radioSomeoneValidatingInvoices')">
                    <mat-radio-button class="m-1 col col-2" [value]=true>
                        <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'YES' | translate }}</span>
                    </mat-radio-button>
                    <mat-radio-button class="m-1 col col-2" [value]=false>
                        <span class="montserrat-regular ts-14 s-black text-wrap">{{ 'NO' | translate }}</span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
    <div class="row g-0 implemented-policies-row">
        <div class="row g-0">
            <span class="montserrat-semibold p-blue fs-14 text-wrap" [class.invalid]="areEquipmentCheckboxesInvalid()">
                {{ 'ENERGY_EFICIENCY.EQUIPMENTS_TITLE' | translate }}*
            </span>
        </div>
        <div class="row g-0">
            <span class="montserrat-italic fs-11 text-wrap sub-title my-3">
                {{ 'ENERGY_EFICIENCY.FIRST_STEP' | translate }}
            </span>
        </div>
        <div class="row g-0">
            <div *ngFor="let equipment of equipments" class="col col-6 mb-3">
                <mat-checkbox class="montserrat-medium fs-14 s-black" (click)="equipmentClick(equipment)" [class.invalid]="areEquipmentCheckboxesInvalid()" [formControlName]="equipment.formName">
                    {{ getTranslationKey(equipment.translation) | translate }}
                </mat-checkbox>
            </div>
        </div>
        <div class="row g-0">
            <span class="montserrat-italic fs-11 text-wrap sub-title my-3">
                {{ 'ENERGY_EFICIENCY.SECOND_STEP' | translate }}
            </span>
        </div>
    </div>
    <div class="row g-0">
        <app-equipment-details *ngFor="let equipment of getEquipmentsClicked()" [receivedFormGroup]="eficiencyFormGroup.get(equipment.formGroupName)" [translation]="equipment.translation" [isOtherEquipment]="isOtherEquipmentForm(equipment.formGroupName)"></app-equipment-details>
    </div>
    <div class="row g-0 d-flex justify-content-between">
        <div class="col col-md-auto">
            <button type="button" (click)="navigateToPreviousPage()" class="btn secundary-button montserrat-regular">{{ 'PREVIOUS' | translate }}</button>
        </div>
        <div class="col col-md-auto">
            <button type="submit" class="btn primary-button montserrat-regular">{{ 'NEXT' | translate }}</button>
        </div>
    </div>
</form>
