<div class="modal-content d-flex align-items-center">
    <span class="montserrat-bold p-blue fs-20">{{ 'SIMULATE' | translate }}</span>
    <span class="montserrat-medium p-blue fs-16 text-center mt-3">{{ 'MODAL.QUESTION' | translate }}</span>
    <mat-checkbox class="montserrat-regular fs-12 s-black mt-3" [(ngModel)]="sendEmail">{{ 'MODAL.CHECKBOX_TEXT' | translate }}</mat-checkbox>
    <div class="row g-0 w-100 d-flex justify-content-between">
        <div class="col col-md-auto">
            <button type="dismiss" class="btn secundary-button montserrat-regular" data-dismiss="modal" (click)="cancel()">{{ 'NO' | translate }}</button>
        </div>
        <div class="col col-md-auto">
            <button type="submit" class="btn primary-button montserrat-regular" (click)="confirm()">{{ 'YES' | translate }}</button>
        </div>
    </div>
    <div class="row g-0 mb-2">
        <span class="montserrat-italic fs-11 text-wrap info">{{ 'MODAL.ADDICIONAL_INFO' | translate }}</span>
    </div>
</div>
