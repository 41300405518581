import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {TimepickerType} from '@domain/timepicker-type.enum';
import {isEqual, range} from 'lodash';

@Component({
    selector: 'app-timepicker',
    templateUrl: './timepicker.component.html',
    styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent implements OnInit
{
    @Input() public timeFormGroup: FormGroup;
    @Input() public type: TimepickerType;
    public hours = range(24).map((hour: number) => hour < 10 ? `0${hour}` : `${hour}`);
    public minutes = ['00', '15', '30', '45'];
    public label: string;

    public ngOnInit(): void
    {
        this.label = isEqual(this.type, TimepickerType.START) ? 'START_TIME' : 'END_TIME';
    }
}
