import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Period} from '@domain/period.enum';
import {TensionLevel} from '@domain/tension-level.enum';
import {TimeFrequency} from '@domain/time-frequency.enum';
import {isEqual} from 'lodash';

@Component({
    selector: 'app-eletricity',
    templateUrl: './eletricity.component.html',
    styleUrls: ['./eletricity.component.scss']
})
export class EletricityComponent implements OnInit
{
    @Input() public eletricityFormGroup: FormGroup;
    public cicles =
        [
            {translation: 'INFO_TO_ATTACH.ELETRICITY_PART.DAILY', value: TimeFrequency.DAILY},
            {translation: 'INFO_TO_ATTACH.ELETRICITY_PART.WEEKLY', value: TimeFrequency.WEEKLY},
            {translation: 'INFO_TO_ATTACH.ELETRICITY_PART.MONTHLY', value: TimeFrequency.MONTHLY}
        ];

    public numberOfPeriods =
        [
            Period.SINGLE,
            Period.BI_SCHEDULE,
            Period.TRI_SCHEDULE,
            Period.TETRA_SCHEDULE
        ];

    public tensionLevelOptions =
        [
            TensionLevel.MT,
            TensionLevel.BTE,
            TensionLevel.BTN
        ];

    public constructor(private readonly fb: FormBuilder)
    { }

    public ngOnInit(): void
    {
        this.eletricityFormGroup.get('numberOfPeriods').valueChanges.subscribe((value) =>
        {
            if (isEqual(value, Period.SINGLE))
            {
                this.removeControls();
                this.eletricityFormGroup.addControl('singlePeriodFormGroup', this.createSinglePeriodFormGroup());
            }
            else if (isEqual(value, Period.BI_SCHEDULE))
            {
                this.removeControls();
                this.eletricityFormGroup.addControl('emptyPeriodFormGroup', this.createSinglePeriodFormGroup());
                this.eletricityFormGroup.addControl('emptyOutPeriodFormGroup', this.createSinglePeriodFormGroup());
            }
            else if (isEqual(value, Period.TRI_SCHEDULE))
            {
                this.removeControls();
                this.createTriSchedule();
            }
            else if (isEqual(value, Period.TETRA_SCHEDULE))
            {
                this.removeControls();
                this.createTriSchedule();
                this.eletricityFormGroup.addControl('superEmptyPeriodFormGroup', this.createSinglePeriodFormGroup());
            }
        });
    }

    private createTriSchedule(): void
    {
        this.eletricityFormGroup.addControl('pontaPeriodFormGroup', this.createSinglePeriodFormGroup());
        this.eletricityFormGroup.addControl('fullPeriodFormGroup', this.createSinglePeriodFormGroup());
        this.eletricityFormGroup.addControl('emptyPeriodFormGroup', this.createSinglePeriodFormGroup());
    }

    public areRadiosInvalid(): boolean
    {
        return this.eletricityFormGroup.get('consumption').touched && this.eletricityFormGroup.get('deliveryPointCode').touched && this.eletricityFormGroup.get('tensionLevel').invalid;
    }

    public getPeriodTranslation(period: Period): string
    {
        return `INFO_TO_ATTACH.ELETRICITY_PART.${period}`;
    }

    public periodSelected(type: string): boolean
    {
        return isEqual(this.eletricityFormGroup.get('numberOfPeriods').value, type);
    }

    public createSinglePeriodFormGroup(): FormGroup
    {
        return this.fb.group({
            price: new FormControl('', Validators.required),
            consumption: new FormControl('', Validators.required)
        });
    }

    public removeControls(): void
    {
        this.eletricityFormGroup.removeControl('singlePeriodFormGroup');
        this.eletricityFormGroup.removeControl('emptyPeriodFormGroup');
        this.eletricityFormGroup.removeControl('emptyOutPeriodFormGroup');
        this.eletricityFormGroup.removeControl('pontaPeriodFormGroup');
        this.eletricityFormGroup.removeControl('fullPeriodFormGroup');
        this.eletricityFormGroup.removeControl('superEmptyPeriodFormGroup');
    }
}
